import gsap from 'gsap';
import * as THREE from 'three';

import { ANIMATION_HELPERS } from '../../const/animations.const';

export const handleScale = (
	mesh: THREE.Mesh | THREE.Object3D,
	multiplier: number,
	duration: number = ANIMATION_HELPERS.durationSmall,
): void => {
	if (!mesh.userData.size || mesh.userData.size === null) {
		gsap.to(mesh.scale, {
			x: multiplier,
			y: multiplier,
			z: multiplier,
			duration,
			ease: ANIMATION_HELPERS.ease,
		});
		return;
	}

	gsap.to(mesh.scale, {
		x: mesh.userData.size.width * multiplier,
		y: mesh.userData.size.height * multiplier,
		z: 1 * multiplier,
		duration,
		ease: ANIMATION_HELPERS.ease,
	});
};
