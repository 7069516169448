import React, { ReactElement } from 'react';

import cx from 'classnames';

import { IButtonProps } from './Button.types';

export const Button: React.FC<IButtonProps> = ({
	label,
	accessibilityLabel,
	type,
	onClickButton,
	className,
}: IButtonProps): ReactElement => (
	<button
		aria-label={accessibilityLabel}
		onClick={onClickButton}
		className={cx('c-button', type && `c-button--${type}`, className)}>
		<span className="c-button__label">{label}</span>
	</button>
);
