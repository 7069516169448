import gsap from 'gsap';

import { ANIMATION_HELPERS } from '../../const/animations.const';
import {
	calculateImageScaleWithFocalPoint,
	calculateImageSizeWithFocalPoint,
	getFocalPointOffset,
} from '../utils/focalPoint';
import { visibleHeightAtZDepth, visibleWidthAtZDepth } from '../utils/getZDepth';

const hideClosebutton = () => {
	const close = document.getElementsByClassName('c-button--close')[0];
	close.classList.add('c-button--hide');
	gsap.to('.c-logo', {
		scale: 1,
		duration: ANIMATION_HELPERS.duration,
		ease: ANIMATION_HELPERS.ease,
	});
};

const createCloseButton = (onComplete: () => void) => {
	const close = document.getElementsByClassName('c-button--close')[0];
	close.classList.remove('c-button--hide');
	close.addEventListener('click', () => {
		hideClosebutton();
		onComplete();
	});

	const menu = document.getElementsByClassName('js-button-menu')[0];
	menu.addEventListener('click', () => {
		hideClosebutton();
		onComplete();
	});
};

const animateHeroCovered = (
	mesh: THREE.Mesh,
	camera: THREE.PerspectiveCamera,
	isResize: boolean,
) => {
	// Info(Silke): Get needed image info
	const { hasFocalPoint, focalPoint, width, height } = mesh.userData.image;

	// Note(Silke): Group data so calculations stay obvious
	const originalImageSize = {
		width,
		height,
	};

	const originalImageScale = {
		x: mesh.scale.x,
		y: mesh.scale.y,
	};

	// Info(Silke): handle focal point - image size
	const croppedImageSize = calculateImageSizeWithFocalPoint(
		hasFocalPoint,
		focalPoint,
		originalImageSize,
	);

	// Info(Silke): handle focal point - image scale
	const croppedImageScale = calculateImageScaleWithFocalPoint(
		hasFocalPoint,
		focalPoint,
		originalImageSize,
		croppedImageSize,
		originalImageScale,
	);

	// Info(Silke): Calculate aspect ratios
	const imageAspectRatio = croppedImageSize.height / croppedImageSize.width;
	const screenAspectRatio = window.innerHeight / window.innerWidth;

	// Info(Silke): Compare image aspect ratio with screen aspect ratio
	const isYAxis = imageAspectRatio / screenAspectRatio < 1;

	// Info(Silke): Calculate the scale values
	// Info(Silke): y axis = scale until the top and bottom of the image reach the edges
	// Info(Silke): x axis = scale until the left and rigth side of the image reach the edges
	const vh = visibleHeightAtZDepth(mesh.position.z, camera);
	const vw = visibleWidthAtZDepth(mesh.position.z, camera);
	const viewportSize = isYAxis ? vh : vw;
	const scale = isYAxis ? croppedImageScale.y : croppedImageScale.x;

	const multiplier = viewportSize / scale;

	// Info(Silke): Final scale values
	const scaleX = multiplier * originalImageScale.x;
	const scaleY = multiplier * originalImageScale.y;

	// Info(Silke): Calculate the offset from the center of the image based on the focal point
	const { offsetX, offsetY } = getFocalPointOffset(
		hasFocalPoint,
		focalPoint,
		scaleX,
		scaleY,
	);

	// Info(Silke): Final positioning values
	const xPos = 0 - (mesh?.parent?.position?.x || 0) - offsetX;
	const yPos = 0 - (mesh?.parent?.position?.y || 0) + offsetY;

	// Info(Silke): Tweak animation values according to the type of animation (on resize / to detail)
	const animationDelay = isResize ? 0 : ANIMATION_HELPERS.durationLarge;
	const animationDuration = isResize
		? ANIMATION_HELPERS.durationXSmall
		: ANIMATION_HELPERS.durationLarge;

	// Animate to fullscreen
	gsap.to(mesh.scale, {
		x: scaleX,
		y: scaleY,
		duration: animationDuration,
		ease: ANIMATION_HELPERS.ease,
		delay: animationDelay,
		onUpdate: () => {
			// Info (Katia): update fragment shader for sharp images
			const uniforms = (mesh.material as THREE.ShaderMaterial).uniforms;
			if (uniforms) {
				uniforms.uBlurStrength.value = 0.0;
			}
			mesh.userData.scale = multiplier;
		},
	});

	// Animate to the center of the screen
	mesh.position.set(mesh.position.x, mesh.position.y, 1);
	gsap.to(mesh.position, {
		x: xPos,
		y: yPos,
		z: 6, // Bring to front
		duration: animationDuration,
		ease: ANIMATION_HELPERS.ease,
		delay: animationDelay,
	});
};

const animateHeroContain = (mesh: THREE.Mesh, camera: THREE.PerspectiveCamera) => {
	const sizes = {
		width: visibleWidthAtZDepth(mesh.position.z, camera),
		height: visibleHeightAtZDepth(mesh.position.z, camera),
	};
	const ratio = mesh.scale.y / mesh.scale.x;
	const isVertical = mesh.scale.y > mesh.scale.x;

	// Animate
	mesh.position.z = 4;
	gsap.to(mesh.position, {
		x: 0 - (mesh?.parent?.position?.x || 0),
		y: 0 - (mesh?.parent?.position?.y || 0),
		duration: ANIMATION_HELPERS.durationLarge,
		ease: ANIMATION_HELPERS.ease,
	});

	if (isVertical) {
		// Calculate max width keeping the aspect ratio in mind
		let fullScreenHeight = sizes.height;
		if (fullScreenHeight / ratio > sizes.width) {
			fullScreenHeight = sizes.width * ratio;
		}

		gsap.to(mesh.scale, {
			x: fullScreenHeight / ratio,
			y: fullScreenHeight,
			z: 1,
			duration: ANIMATION_HELPERS.durationLarge,
			ease: ANIMATION_HELPERS.ease,
		});
	} else {
		// Calculate max width keeping the aspect ratio in mind
		let fullScreenWidth = sizes.width;
		if (ratio * fullScreenWidth > sizes.height) {
			fullScreenWidth = sizes.height / ratio;
		}

		gsap.to(mesh.scale, {
			x: fullScreenWidth,
			y: fullScreenWidth * ratio,
			z: 1,
			duration: ANIMATION_HELPERS.durationLarge,
			ease: ANIMATION_HELPERS.ease,
		});
	}
};

export const animateHero = (
	mesh: THREE.Mesh,
	camera: THREE.PerspectiveCamera,
	isResize: boolean,
	hasLink: boolean,
	onComplete: () => void = (): void => {},
): void => {
	if (!mesh.userData.image) {
		return;
	}

	if (!hasLink) {
		createCloseButton(onComplete);
		animateHeroContain(mesh, camera);
	} else {
		animateHeroCovered(mesh, camera, isResize);
	}
};
