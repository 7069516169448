let supportsWebp: boolean | null = null;

const detectWebpSupport = () => {
	if (supportsWebp !== null) {
		return;
	}

	const canvas: HTMLCanvasElement | null =
		typeof document === 'object' ? document.createElement('canvas') : null;

	if (!canvas) {
		supportsWebp = false;
		return;
	}

	canvas.width = canvas.height = 1;
	supportsWebp = canvas.toDataURL
		? canvas.toDataURL('image/webp').indexOf('image/webp') === 5
		: false;

	if (supportsWebp) {
		supportsWebp = true;
	} else {
		supportsWebp = false;
	}
};

export { supportsWebp, detectWebpSupport };
