import gsap from 'gsap';
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';

import { ANIMATION_HELPERS, ANIMATION_TYPE } from '../const/animations.const';
import { GRID_CONTENT_BLOCK_TYPES } from '../const/data.const';
import {
	IGridContentItem,
	IGridContentLink,
	IGridTexture,
} from '../types/grid.types';
import { detectIOS } from '../utils/detectBrowser.util';
import getWindowSize from '../utils/getWindow.util';
import { isIntroEnabled } from '../utils/isIntroEnabled';
import { handleBlur } from './animations/blur.animations';
import { animateHero } from './animations/hero.animations';
import { handleOpacity } from './animations/opacity.animations';
import {
	resetTransition,
	transitionToDetail,
} from './animations/transition.animations';
import Camera from './classes/camera.class';
import Content from './classes/content.class';
import Frustum from './classes/frustum.class';
import Image from './classes/image.class';
import Layout from './classes/layout.class';
import Renderer from './classes/renderer.class';
import Textures from './classes/textures.class';
import Video from './classes/video.class';
import Drag from './events/drag.event';
import Wheel from './events/wheel.event';
import getMeshes from './utils/getMeshes';
import { lerp } from './utils/lerp';

let camera: Camera;
let renderer: Renderer;
let scene: THREE.Scene;
let textures: Textures;
let drag: Drag;
let layout: Layout;
let wheel: Wheel;
let frustum: Frustum;
let content: Content;
let prevMouse = { x: 0, y: 0 };
let isLoaded = false;
let isFloatEnabled = !isIntroEnabled;

let stats: Stats;
let currentIntersect: THREE.Intersection | null = null;
const raycaster = new THREE.Raycaster();

let sizes = getWindowSize();

let canvas: HTMLCanvasElement | null;
let activeMesh: THREE.Mesh | null = null;

let mounted = false;
let isInit = false;
let isVisible = false;

let clickCallback: (link: IGridContentLink) => void;
let transitionCompleteCallback: () => void;

// Create materials once
const basicMaterial = new THREE.MeshBasicMaterial({
	transparent: true, // Do not disable, this is used by opacity animations
	color: '#000000',
});

const enableDebug = process.env.ENABLE_DEBUG === 'true';
const isIOS = detectIOS();

const init = (
	clickCb: (link: IGridContentLink) => void,
	transitionCompleteCb: () => void,
): void => {
	canvas = document.querySelector('.js-canvas');

	if (!canvas) {
		return;
	}

	clickCallback = clickCb;
	transitionCompleteCallback = transitionCompleteCb;

	scene = new THREE.Scene();
	camera = new Camera(sizes, handleVisibility);
	renderer = new Renderer(canvas, sizes);
	frustum = new Frustum(camera.camera);
	mounted = true;

	if (enableDebug) {
		stats = Stats();
		document.body.appendChild(stats.dom);
	}

	if (isIntroEnabled) {
		handleIntro();
	}
};

// Info(Katia): Re-init all listeners and renderers
const reinit = (): void => {
	canvas = document.querySelector('.js-canvas');

	if (!canvas || !drag) {
		return;
	}

	sizes = getWindowSize();
	(canvas as HTMLCanvasElement).width = sizes.width;
	(canvas as HTMLCanvasElement).height = sizes.height;

	// Info(Katia): Prevent flash when returning
	if (renderer.canvas === canvas) {
		renderer.update(sizes);
		renderer.render(scene, camera.camera);
	} else {
		renderer = new Renderer(canvas, sizes);
	}

	camera.resize(sizes);
	drag.reinit(canvas);
	wheel.setIsEnabled(true);
	activeMesh = null;

	if (scene) {
		const meshes = getMeshes(scene.children);
		// Info(Kaita): Make the 'back' animation
		resetTransition(meshes, (): void => {
			transitionCompleteCallback();
			isFloatEnabled = true;
		});
	}

	// Resize
	frustum.updateFromCamera(camera.camera);
	drag.resize();
	layout.resize(cleanScene, rebuildScene);
	handleVisibility();

	if (!mounted) {
		mounted = true;
		render();
	}
};

/**
 * Loaders
 */
const loadCallback = (): void => {
	if (isInit) {
		return;
	}

	addEventListeners();
	generateLayout();

	render();
	isInit = true;
};

const loadContent = (
	highlights: IGridContentItem[],
	extended: IGridContentItem[],
): void => {
	const loader = new THREE.LoadingManager(
		// Loaded
		() => {
			loadCallback();
		},
	);

	textures = new Textures(loader);

	content = new Content(textures, highlights);

	if (highlights.length) {
		const highlightTextures = highlights.filter(
			(highlight) => highlight.type === GRID_CONTENT_BLOCK_TYPES.IMAGE,
		);
		textures.loadTextures(highlightTextures, true, 1);
	}
	textures.loadTextures(extended, false);
};

/**
 * Events
 */
const cleanScene = () => {
	scene.remove(layout.layout);
};

const rebuildScene = () => {
	scene.add(layout.layout);
};

const handleResize = (): void => {
	const updatedSizes = getWindowSize();

	if (
		sizes.width === updatedSizes.width &&
		sizes.height === updatedSizes.height &&
		isIOS
	) {
		// Prevent unnecessary resize calls on ios scrolling
		return;
	}

	sizes = getWindowSize();

	camera.resize(sizes);

	(canvas as HTMLCanvasElement).width = sizes.width;
	(canvas as HTMLCanvasElement).height = sizes.height;

	if (frustum) {
		frustum.updateFromCamera(camera.camera);
	}

	if (drag) {
		drag.resize();
	}

	if (layout) {
		layout.resize(cleanScene, rebuildScene);
	}

	handleVisibility();

	if (activeMesh) {
		animateHero(activeMesh, camera.camera, true, !!activeMesh.userData.link);
	}

	renderer.update(sizes);
};

const handleClick = (): void => {
	if (currentIntersect && isLoaded) {
		const { userData, uuid }: THREE.Object3D = currentIntersect?.object;
		const { link, type, id, gridIndex } = userData;

		if (type === GRID_CONTENT_BLOCK_TYPES.AUDIO) {
			const audioInstance = layout.gridRecord[gridIndex];

			if (audioInstance) {
				audioInstance.toggleAudio(id);
			}
			return;
		}

		if (link && link.external) {
			// Navigation
			clickCallback(link as IGridContentLink);

			// Reset Zoom
			camera.resetZoom();

			return;
		}

		if (
			type === GRID_CONTENT_BLOCK_TYPES.IMAGE ||
			type === GRID_CONTENT_BLOCK_TYPES.VIDEO
		) {
			// Disable zoom and scroll
			drag.setIsEnabled(false);
			wheel.setIsEnabled(false);
			isFloatEnabled = false;

			const meshes: THREE.Mesh[] = getMeshes(scene.children) || [];
			const clickedMesh = meshes.find(
				(mesh: THREE.Mesh) => mesh.uuid === uuid,
			);
			const inactiveMeshes = meshes.filter(
				(mesh: THREE.Mesh) => mesh.uuid !== uuid,
			);

			// Navigation
			if (link && link.internal) {
				clickCallback(link as IGridContentLink);
				if (userData.link) {
					clickCallback(userData.link as IGridContentLink);
				}
			}

			// Reset Zoom
			camera.resetZoom();

			// Load HQ Image
			if (!clickedMesh?.userData.isHQ && clickedMesh && userData.link) {
				textures.replaceTexture(
					clickedMesh?.userData.id,
					(HQTexture) => {
						const uniforms = (
							clickedMesh.material as THREE.ShaderMaterial
						).uniforms;

						if (uniforms) {
							uniforms.uTexture.value = HQTexture?.texture;
							clickedMesh.userData.isHQ = true;
						}
					},
					true,
				);
			}

			// Animate Logo
			gsap.to('.c-logo', {
				scale: 0,
				duration: ANIMATION_HELPERS.duration,
				ease: ANIMATION_HELPERS.ease,
			});

			// Animate meshes
			if (clickedMesh && inactiveMeshes) {
				activeMesh = clickedMesh;
				transitionToDetail(
					camera.camera,
					clickedMesh,
					inactiveMeshes,
					!!link && !!link.internal,
					reinit,
					() => {
						const meshes = getMeshes(scene.children);
						resetTransition(meshes, (): void => {
							transitionCompleteCallback();
						});
						isFloatEnabled = true;
						drag.setIsEnabled(true);
						wheel.setIsEnabled(true);
						handleVisibility();
					},
				);
			}
		}
	}
};

const addEventListeners = (): void => {
	wheel = new Wheel(camera);
	drag = new Drag(canvas as HTMLCanvasElement, wheel, handleClick);

	camera.setDrag(drag);
};

const handleHover = (): void => {
	if (!isLoaded) {
		return;
	}

	const intersects = raycaster.intersectObjects(scene.children);
	if (intersects.length) {
		if (currentIntersect === null) {
			const mesh = intersects[intersects.length - 1]?.object as THREE.Mesh;
			const type = ANIMATION_TYPE.OUT;
			if (mesh.userData.ignoreHover === true) {
				return;
			}
			handleBlur(mesh, type);
		}
		currentIntersect = intersects[intersects.length - 1];
	} else {
		if (currentIntersect) {
			const mesh = currentIntersect?.object as THREE.Mesh;
			const type = ANIMATION_TYPE.IN;
			if (mesh.userData.ignoreHover === true) {
				currentIntersect = null;
				return;
			}
			handleBlur(mesh, type);
		}
		currentIntersect = null;
	}
};

/**
 * FUNCTIONS
 */
const generateLayout = () => {
	layout = new Layout(textures, content, basicMaterial, camera, handleVisibility);
	scene.add(layout.layout);

	isLoaded = true;
	isFloatEnabled = true;
	handleInitialVisiblity();
};

const handleInitialVisiblity = (isIntro?: boolean) => {
	// Only update first grid
	if (!scene || !scene.children.length) {
		return;
	}

	const firstGrid = scene.children[0].children[0] as THREE.Group;
	handleVisibility(firstGrid, isIntro);
};

const handleIntro = () => {
	gsap.fromTo(
		'.js-button-menu',
		{
			opacity: 0,
		},
		{
			opacity: 1,
			duration: ANIMATION_HELPERS.duration,
			delay: 1,
		},
	);
};

/**
 * Info(Katia): All items will be invisible to prevent unnecessary renders and animation.
 * When in view(checked  with the frustum) the items will be set visible
 */
const handleVisibility = (group?: THREE.Group, isIntro: boolean = false) => {
	let visibleMeshes = 3;
	const meshes = getMeshes(group ? group.children : scene.children);
	for (let i = 0; i < meshes.length; i++) {
		const mesh = meshes[i];
		const isText = (mesh as any)._private_text;
		const isAudio =
			(mesh as any).userData.type === GRID_CONTENT_BLOCK_TYPES.AUDIO;

		if (frustum.isInView(mesh) || isText || isAudio) {
			const isText = mesh.userData.type === GRID_CONTENT_BLOCK_TYPES.TEXT;
			const isImage = mesh.userData.type === GRID_CONTENT_BLOCK_TYPES.IMAGE;
			const isVideo = mesh.userData.type === GRID_CONTENT_BLOCK_TYPES.VIDEO;
			const isAudio = mesh.userData.type === GRID_CONTENT_BLOCK_TYPES.AUDIO;

			const isImageLoaded = mesh.userData.texture;
			const isVideoLoaded = mesh.userData.isVideoLoaded;
			const isVideoLoading = mesh.userData.isVideoLoading;
			if (!isImageLoaded && isImage) {
				// Info(Katia): Load the image only when visible
				textures.replaceTexture(
					mesh.userData.id,
					(texture: IGridTexture) => {
						const image = new Image(mesh);
						image.setTexture(texture);
						image.updateTexture();
						mesh.visible = true;
						mesh.userData.texture = texture;
					},
					false,
				);
			} else if (!isVideoLoaded && !isVideoLoading && isVideo) {
				new Video(
					mesh as THREE.Mesh,
					mesh.userData.videoId,
					mesh.userData.videoLink,
					camera,
				);
				mesh.visible = true;
			} else if ((isText || isAudio) && isIntro && !mesh.visible) {
				handleOpacity(mesh, ANIMATION_TYPE.IN);
				mesh.visible = true;
			} else {
				mesh.visible = true;
			}

			if (!isText && !isAudio) {
				visibleMeshes += 1;
			}
		} else {
			mesh.visible = false;
		}
	}

	if (visibleMeshes > 4) {
		isVisible = true;
	}
};

const setMounted = (updatedMounted: boolean): void => {
	mounted = updatedMounted;
};

// Info(Katia): Move the grid on mouse movement
const handleFloatingAnimation = () => {
	if (!isFloatEnabled) {
		return;
	}

	const mouse = drag.normalized;
	const meshes = getMeshes(scene.children);
	for (let i = 0; i < meshes.length; i++) {
		const mesh = meshes[i];
		if (mesh.visible && mesh.userData.position) {
			const updatePostion = {
				x: lerp(
					mesh.position.x,
					mesh.userData.position.x + mouse.x * 20,
					0.2,
				),
				y: lerp(
					mesh.position.y,
					mesh.userData.position.y + mouse.y * 20,
					0.2,
				),
			};
			mesh.position.set(
				updatePostion.x,
				updatePostion.y,
				mesh.userData.position.z,
			);
		}
	}
};

/**
 * RENDER
 */
const render = (): void => {
	if (!drag || !layout || !camera || !frustum || !raycaster || !renderer) {
		return;
	}

	drag.update();
	layout.update(drag.current);

	camera.update();
	frustum.updateFromCamera(camera.camera);

	if (drag.normalized !== prevMouse) {
		raycaster.setFromCamera(drag.normalized, camera.camera);
		handleHover();
		handleFloatingAnimation();
		prevMouse = drag.normalized;
	}

	renderer.render(scene, camera.camera);

	if (mounted) {
		window.requestAnimationFrame(render);
	} else {
		// Clean up renderer
		renderer.renderer.dispose();
	}

	if (enableDebug) {
		stats.update();
	}

	if (!isVisible && !isIntroEnabled) {
		handleInitialVisiblity();
	}
};

export { init, handleResize, loadContent, setMounted, reinit };
