/**
 * GRID
 * A grid is a set of meshes that is repeated in the layout,
 * normally there are 9 grids in the layout that are repeated
 */
import * as THREE from 'three';

import { GRID_CONTENT_BLOCK_TYPES } from '../../const/data.const';
import { IImage } from '../../types/data.types';
import {
	IGridAudio,
	IGridContent,
	IGridText,
	IGridTexture,
	IGridVersion,
	IGridVideo,
} from '../../types/grid.types';
import getWindowSize from '../../utils/getWindow.util';
import { getRandomArrayValue } from '../../utils/random.util';
import { getBoxSize } from '../utils/getBoxSize';
import Audio from './audio.class';
import Camera from './camera.class';
import Content from './content.class';
import Image from './image.class';
import Text from './text.class';
import Textures from './textures.class';
import Video from './video.class';

class Grid {
	public grid = new THREE.Group();

	private gridVersion = new THREE.Group();
	private gridVersions: THREE.Group[];
	private position = new THREE.Vector2();
	private index = new THREE.Vector2();
	private size = new THREE.Vector3();
	private jumpAmount = 3; // Should jump 2 places
	private sizes = getWindowSize();
	private gap = this.sizes.width > this.sizes.height ? 150 : 25;
	private versions: IGridVersion[] = [];
	private textures: Textures;
	private usedContent: IGridContent[] = [];
	private camera: Camera;
	// Images will load within this bounding box
	private edges = {
		width: 0,
		height: 0,
	};
	private content: Content;
	private performanceCallback: (group?: THREE.Group) => void;
	private audioInstances: Record<string, Audio> = {};

	constructor(
		gridVersions: THREE.Group[],
		textures: Textures,
		content: Content,
		index: THREE.Vector2,
		initIndex: number,
		camera: Camera,
		performanceCallback: (group?: THREE.Group) => void,
	) {
		this.textures = textures;
		this.content = content;
		this.gridVersions = gridVersions;
		this.gridVersion = this.getRandomGrid();
		this.index = index;
		this.performanceCallback = performanceCallback;
		this.camera = camera;

		this.setSize();
		this.generatePosition();
		this.generate();
		this.generateMedia(initIndex);
		this.versions.push(this.getVersion());
	}

	getRandomGrid(): THREE.Group {
		return getRandomArrayValue(this.gridVersions);
	}

	getVersion(): IGridVersion {
		// Info(Katia): Make copies otherwise you get the updated version
		return {
			index: new THREE.Vector2().copy(this.index),
			content: [...this.usedContent],
		};
	}

	generatePosition() {
		this.position = new THREE.Vector2(
			(this.edges.width + this.gap) * this.index.x,
			(this.edges.height + this.gap) * this.index.y,
		);
	}

	generate(): void {
		this.grid = this.grid.copy(this.gridVersion);
		this.grid.position.set(this.position.x, this.position.y, 0);
	}

	setSize(): void {
		this.size = getBoxSize(this.gridVersion);

		this.edges = {
			width: this.size.x,
			height: this.size.y,
		};
	}

	updateUserData(mesh: THREE.Mesh, content: IGridContent): void {
		if (!content || !mesh) {
			return;
		}
		const { type, id } = content;

		const defaultContent = {
			type,
			id,
		};

		/** VIDEO */
		if (type === GRID_CONTENT_BLOCK_TYPES.VIDEO) {
			mesh.userData = {
				...mesh.userData,
				...defaultContent,
				videoId: (content as IGridVideo).videoId,
			};
			return;
		}

		/** IMAGE */
		if (type === GRID_CONTENT_BLOCK_TYPES.IMAGE) {
			const image: IImage | undefined = (content as IGridTexture).image;
			const link = (content as IGridTexture).link;

			mesh.userData = {
				...mesh.userData,
				...defaultContent,
				link,
				image,
				isHQ: (content as IGridTexture).isHQ,
				texture: (content as IGridTexture).texture,
			};
			return;
		}

		/** TEXT */
		if (type === GRID_CONTENT_BLOCK_TYPES.TEXT) {
			const link = (content as IGridText).link;
			mesh.userData = {
				...mesh.userData,
				...defaultContent,
				link,
				text: (content as IGridText).text,
				background: (content as IGridText).background,
			};
		}

		if (type === GRID_CONTENT_BLOCK_TYPES.AUDIO) {
			const gridAudio = content as IGridAudio;
			const link = gridAudio.link;

			mesh.userData = {
				...mesh.userData,
				...defaultContent,
				link,
				audio: gridAudio.audio,
			};
		}
	}

	generateMedia(index?: number): void {
		this.usedContent = [];

		for (let i = 0; i < this.grid.children.length; i++) {
			const mesh = this.grid.children[i];

			let contentItem: IGridContent = this.textures.getRandomTexture();
			if (index !== undefined) {
				// Info(Katia): First images not really random to prevent duplicates in the first views
				const contentIndex = index * this.grid.children.length + i;
				contentItem = this.content.getContent(contentIndex);
				this.usedContent.push(contentItem);
			} else {
				// Hide mesh until new image is loaded
				mesh.visible = false;
			}

			if (!contentItem) {
				return;
			}

			this.updateUserData(mesh as THREE.Mesh, contentItem);

			const { type } = contentItem;

			switch (type) {
				case GRID_CONTENT_BLOCK_TYPES.IMAGE:
					this.generateImage(
						mesh as THREE.Mesh,
						contentItem as IGridTexture,
					);
					break;

				case GRID_CONTENT_BLOCK_TYPES.TEXT:
					this.generateText(mesh as THREE.Mesh, contentItem as IGridText);
					break;

				case GRID_CONTENT_BLOCK_TYPES.AUDIO:
					this.generateAudio(
						mesh as THREE.Mesh,
						contentItem as IGridAudio,
						index || 0,
					);
					break;

				case GRID_CONTENT_BLOCK_TYPES.VIDEO:
					this.generateVideo(
						mesh as THREE.Mesh,
						contentItem as IGridVideo,
						i === 0,
					);
					break;
			}
		}
	}

	generateAudio(mesh: THREE.Mesh, audio: IGridAudio, gridIndex: number): void {
		const audioItem = new Audio(mesh, audio, gridIndex);
		this.audioInstances[mesh.userData.id] = audioItem;
	}

	generateText(mesh: THREE.Mesh, contentItem: IGridText): void {
		new Text(mesh as THREE.Mesh, contentItem);
	}

	generateVideo(mesh: THREE.Mesh, contentItem: IGridVideo, isMain = false): void {
		new Video(
			mesh as THREE.Mesh,
			contentItem.videoId,
			contentItem.videoLink,
			this.camera,
			isMain,
		);
	}

	generateImage(mesh: THREE.Mesh, texture: IGridTexture): void {
		if (!texture?.texture) {
			return;
		}

		const image = new Image(mesh);
		image.setTexture(texture);
		image.updateTexture();
	}

	checkJump() {
		let jump = false;
		const edge = {
			x: this.size.x * (this.jumpAmount - 1),
			y: this.size.y * (this.jumpAmount - 1),
		};

		// Left to right
		if (this.grid.position.x < edge.x * -1) {
			jump = true;
			this.position.x =
				this.position.x +
				this.size.x * this.jumpAmount +
				this.gap * this.jumpAmount;
			this.index.x += this.jumpAmount;
		}

		// Right to left
		if (this.grid.position.x > edge.x) {
			jump = true;
			this.position.x =
				this.position.x -
				this.size.x * this.jumpAmount -
				this.gap * this.jumpAmount;
			this.index.x -= this.jumpAmount;
		}

		// Top to bottom
		if (this.grid.position.y > edge.y) {
			jump = true;
			this.position.y =
				this.position.y -
				this.size.y * this.jumpAmount -
				this.gap * this.jumpAmount;
			this.index.y -= this.jumpAmount;
		}

		// Bottom to top
		if (this.grid.position.y < edge.y * -1) {
			jump = true;
			this.position.y =
				this.position.y +
				this.size.y * this.jumpAmount +
				this.gap * this.jumpAmount;
			this.index.y += this.jumpAmount;
		}

		if (jump) {
			this.grid.position.set(this.position.x, this.position.y, 0);
			this.updateMedia();
		}
	}

	checkIfGridIsInView() {
		// Info(Katia): When grid comes within edges, load and render images
		if (
			this.grid.position.x > this.edges.width * -1 &&
			this.grid.position.x < this.edges.width &&
			this.grid.position.y > this.edges.height * -1 &&
			this.grid.position.y < this.edges.height
		) {
			this.grid.visible = true;
			this.performanceCallback(this.grid);
		} else {
			this.grid.visible = false;
		}
	}

	toggleAudio(id: string): void {
		this.audioInstances[id].toggle();
	}

	updateMedia(): void {
		const prevMedia = this.versions.find(
			(version: IGridVersion) =>
				version.index.x === this.index.x && version.index.y === this.index.y,
		);

		// Info(Katia): Render old media back into the tiles
		if (prevMedia) {
			for (let i = 0; i < prevMedia.content.length; i++) {
				const texture = prevMedia.content[i];
				const mesh = this.grid.children[i] as THREE.Mesh;
				this.updateUserData(mesh, texture);
			}

			return;
		}

		// Info(Katia): Generate new images into the tiles
		this.generateMedia();
		this.versions.push(this.getVersion());
	}

	move(x: number, y: number): void {
		// Info(Katia): when the tile reaches a certain point, jump to a new position
		this.grid.position.set(this.position.x + x, this.position.y + y, 0);
		this.checkJump();
		this.checkIfGridIsInView();
	}

	resize() {
		this.sizes = getWindowSize();
	}
}

export default Grid;
