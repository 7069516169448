export enum IMAGE_LOADING_TYPES {
	Eager = 'eager',
	Lazy = 'lazy',
}

export enum IMAGE_DECODING_TYPES {
	Sync = 'sync',
	Async = 'async',
	Auto = 'auto',
}
