import { DEVICES } from '../const/devices.const';

export const detectMobileOrTablet = (): DEVICES => {
	if (typeof navigator === 'undefined') {
		return DEVICES.DESKTOP;
	}

	const ua = navigator.userAgent;
	const isIpad =
		navigator.maxTouchPoints &&
		navigator.maxTouchPoints > 2 &&
		ua.includes('Mac') &&
		!ua.includes('iPhone');

	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) || isIpad) {
		return DEVICES.TABLET;
	} else if (
		/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua,
		)
	) {
		return DEVICES.MOBILE;
	}
	return DEVICES.DESKTOP;
};
