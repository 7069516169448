import Camera from '../classes/camera.class';

class Wheel {
	private camera: Camera;
	private SPEED = 0.5;
	private isEnabled = true;
	private target: number = 0;

	constructor(camera: Camera) {
		this.camera = camera;
		this.setListeners();
	}

	setListeners() {
		if (typeof window !== 'undefined') {
			window.addEventListener('mousewheel', this.onWheel.bind(this));
			window.addEventListener('wheel', this.onWheel.bind(this));
		}
	}

	unsetListeners() {
		if (typeof window !== 'undefined') {
			window.removeEventListener('mousewheel', this.onWheel.bind(this));
			window.removeEventListener('wheel', this.onWheel.bind(this));
		}
	}

	onWheel(e: Event): void {
		this.updateTarget();

		if (!this.isEnabled) {
			return;
		}

		if ((e as WheelEvent).deltaY < 0) {
			// Info(Katia): Zoom in
			this.target += this.SPEED;
		}

		if ((e as WheelEvent).deltaY > 0) {
			// Info(Katia): Zoom out
			this.target -= this.SPEED;
		}

		this.updateCamera();
	}

	zoomIn(speed?: number): void {
		// Info(Katia): Zoom in
		this.target += speed || this.SPEED;
	}

	zoomOut(speed?: number): void {
		// Info(Katia): Zoom out
		this.target -= speed || this.SPEED;
	}

	updateCamera(): void {
		this.camera.updateZoom(this.target);
	}

	updateTarget(): void {
		this.target = this.camera.camera.zoom;
	}

	setIsEnabled(isEnabled: boolean): void {
		this.isEnabled = isEnabled;
		this.isEnabled ? this.setListeners() : this.unsetListeners();
	}
}

export default Wheel;
