import { GRID_CONTENT_BLOCK_TYPES } from '../../const/data.const';
import {
	IGridAudio,
	IGridContent,
	IGridContentItem,
	IGridText,
	IGridTexture,
	IGridVideo,
} from '../../types/grid.types';
import Textures from './textures.class';

class Content {
	public highlights: IGridContentItem[] = [];

	private textures: Textures;
	private highlightImageIndex = 0;

	constructor(textures: Textures, highlights: IGridContentItem[]) {
		this.textures = textures;
		this.highlights = highlights;
	}

	getImage(): IGridTexture {
		const texture = this.textures.texturesHighlight[this.highlightImageIndex];
		this.highlightImageIndex += 1;
		return texture;
	}

	getContent(i: number): IGridContent {
		if (i < this.highlights.length) {
			const highlight = this.highlights[i];
			const type = highlight.type;

			if (type === GRID_CONTENT_BLOCK_TYPES.IMAGE) {
				return this.getImage();
			}

			if (type === GRID_CONTENT_BLOCK_TYPES.TEXT) {
				return {
					...highlight,
					isHighlight: true,
				} as IGridText;
			}

			if (type === GRID_CONTENT_BLOCK_TYPES.AUDIO) {
				return {
					...highlight,
					isHighlight: true,
				} as IGridAudio;
			}

			if (highlight.type === GRID_CONTENT_BLOCK_TYPES.VIDEO) {
				return {
					...highlight,
					isHighlight: true,
				} as IGridVideo;
			}
		}

		// Info(Katia): Calculate index from default images
		const textureIndex =
			(i - (this.highlights.length - 1)) % (this.textures.textures.length - 1);
		return this.textures.textures[textureIndex];
	}
}

export default Content;
