/**
 * Info(Katia): All production assets have a relative url handled by Cloudfront
 */
const getRelativeUrl = (url: string): string => {
	const isDevAsset = url.includes('raf-simons-dev');
	if (
		process.env.ENABLE_CLOUDFRONT === 'true' &&
		typeof window !== 'undefined' &&
		!isDevAsset
	) {
		const substring = url.split('external-assets');

		return `https://rafsimons.com/external-assets${substring[1]}`;
	}
	return url;
};

export default getRelativeUrl;
