import { IGridItem } from '../../types/grid.types';

// Original
const items: IGridItem[] = [
	{
		x: 0,
		y: 0,
		width: 300,
		height: 400,
	},
	{
		x: 650,
		y: 250,
		width: 300,
		height: 200,
	},
	{
		x: -350,
		y: -400,
		width: 300,
		height: 200,
	},
	{
		x: 200,
		y: 450,
		width: 300,
		height: 100,
	},
	{
		x: -750,
		y: 400,
		width: 300,
		height: 300,
	},
	{
		x: -350,
		y: 150,
		width: 150,
		height: 300,
	},
	{
		x: 550,
		y: -150,
		width: 150,
		height: 300,
	},

	{
		x: -800,
		y: -500,
		width: 150,
		height: 150,
	},
	// EDGES
	{
		x: -250,
		y: 500,
		width: 250,
		height: 150,
	},
	{
		x: 350,
		y: -550,
		width: 350,
		height: 250,
	},
	{
		x: -850,
		y: -200,
		width: 250,
		height: 300,
	},
	{
		x: 800,
		y: -350,
		width: 150,
		height: 300,
	},
];

export { items };
