import React, { ReactElement } from 'react';

import cx from 'classnames';

import { CONTENT_BLOCK_TYPES } from '../../const/data.const';
import { IImage } from '../../types/data.types';
import { Image } from '../Image';
import { RichText } from '../RichText';
import { Video } from '../Video';
import { IContentBlockProps } from './ContentBlock.types';

export const ContentBlock: React.FC<IContentBlockProps> = ({
	data,
	className,
	shouldPlayVideo,
}: IContentBlockProps): ReactElement => {
	const { id, typeHandle, videoId, images, description, sizes, srcsetSizes } =
		data;

	const isVideo = typeHandle === CONTENT_BLOCK_TYPES.VIDEO && videoId;
	const isRichText = typeHandle === CONTENT_BLOCK_TYPES.RICHTEXT && description;
	const isImages =
		typeHandle === CONTENT_BLOCK_TYPES.IMAGES && images && images.length > 0;

	return (
		<div className={cx('c-content-block', className)}>
			{isVideo && <Video key={id} id={videoId} isPlaying={shouldPlayVideo} />}

			{isRichText && <RichText content={description} className="u-layout" />}

			{isImages && (
				<div className="c-content-block__images u-grid">
					{images?.map((img: IImage) => (
						<Image
							key={img.id}
							image={img}
							srcsetSizes={srcsetSizes as number[]}
							sizes={sizes as string}
							wrapperClassName="c-content-block__image"
							objectFit="contain"
						/>
					))}
				</div>
			)}
		</div>
	);
};
