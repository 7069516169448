const getMeshes = (children: any): THREE.Mesh[] => {
	const mappedChildren = [];

	for (const child of children) {
		if (child.children.length) {
			if (child.type === 'Mesh') {
				mappedChildren.push(child);
			} else {
				mappedChildren.push(...getMeshes(child.children));
			}
		} else {
			mappedChildren.push(child);
		}
	}

	return mappedChildren;
};

export default getMeshes;
