import { ICoordinate } from '../../types/window.types';

export const getDistanceBetween = (p1: ICoordinate, p2: ICoordinate): number => {
	const xDiff = p1.x - p2.x;
	const yDiff = p1.y - p2.y;

	return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

export const map = (
	value: number,
	x1: number,
	y1: number,
	x2: number,
	y2: number,
): number => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
