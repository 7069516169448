import * as THREE from 'three';

import { DEVICES } from '../../const/devices.const';
import { PERF } from '../../const/perf.const';
import { IImage } from '../../types/data.types';
import { IGridContentItem, IGridTexture } from '../../types/grid.types';
import { detectMobileOrTablet } from '../../utils/detectMobileTablet.util';
import { getPerf } from '../../utils/detectPerf.util';
import getRelativeUrl from '../../utils/getRelativeUrl.util';
import { getRandomArrayValue } from '../../utils/random.util';
import { supportsWebp } from '../../utils/supportsWebp.util';

class Textures {
	public textureLoader: THREE.TextureLoader;

	public textures: IGridTexture[] = [];
	public texturesHighlight: IGridTexture[] = [];

	private device = detectMobileOrTablet();
	private perf = getPerf();

	constructor(loadingManager: THREE.LoadingManager) {
		this.textureLoader = new THREE.TextureLoader(loadingManager);
	}

	loadTextures(items: IGridContentItem[], isHighlight: boolean, HQLength = 0) {
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			const texture = this.generateTexture(
				item,
				isHighlight,
				// Info(Katia): Load 1 so callback is called. Loading more is not useful because images are rendered random
				isHighlight || i < 1,
				i < HQLength,
			);
			if (texture && isHighlight) {
				this.texturesHighlight.push(texture);
			}

			if (texture && !isHighlight) {
				this.textures.push(texture);
			}
		}
	}

	replaceTexture(
		id: string,
		onLoaded: (texture: IGridTexture) => void,
		isHQ?: boolean,
	) {
		const item = this.textures.find((texture) => {
			if (texture.id === id) {
				return true;
			}

			return false;
		});
		if (!item || !item?.image) {
			return;
		}

		if (!item.texture || (!item.isHQ && isHQ)) {
			const url = this.getTextureUrl(item.image, isHQ);
			const texture = this.textureLoader.load(getRelativeUrl(url), () => {
				item.texture = texture;
				item.isHQ = isHQ;
				item.loaded = true;
				onLoaded(item);
			});
		} else if (item.loaded) {
			onLoaded(item);
		}
	}

	generateTexture = (
		item: IGridContentItem,
		isHighlight: boolean,
		shouldLoad: boolean = false,
		isHQ?: boolean,
	): IGridTexture | undefined => {
		if (!item?.image) {
			return;
		}

		const aspectRatio = (item.image.height || 1) / (item.image.width || 1);

		let texture = null;
		if (shouldLoad) {
			const url = this.getTextureUrl(item.image, isHQ);
			texture = this.textureLoader.load(getRelativeUrl(url));
		}

		return {
			id: item.id,
			type: item.type,
			aspectRatio,
			link: item.link,
			image: item.image,
			isHQ,
			isHighlight,
			texture,
			loaded: texture !== null,
		};
	};

	getRandomTexture = (): IGridTexture => {
		return getRandomArrayValue(this.textures);
	};

	// Info(Katia): Responsive images in the grid
	getTextureUrl = (image: IImage, isHQ: boolean | undefined): string => {
		let url = image?.urlNQ as string;

		if (supportsWebp === false) {
			url = image?.urlNQFallback as string;
		}

		if (isHQ && supportsWebp) {
			url = image?.urlHQ as string;
		}

		if (isHQ && supportsWebp === false) {
			url = image?.urlHQFallback as string;
		}

		if (this.device === DEVICES.MOBILE || this.perf < PERF.PERF_GOOD) {
			url = image?.urlLQ as string;

			if (supportsWebp === false) {
				url = image?.urlLQFallback as string;
			}

			if (isHQ && supportsWebp) {
				url = image?.urlNQ as string;
			}

			if (isHQ && supportsWebp === false) {
				url = image?.urlNQFallback as string;
			}
		}

		return url;
	};
}

export default Textures;
