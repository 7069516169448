import React, { ReactElement } from 'react';

import cx from 'classnames';

import { useAppContext } from '../../hooks/context/appContext.hook';
import { useButtons } from '../../hooks/data/buttons.hook';
import { BUTTON_TYPES, Button } from '../Button';
import { FullHeightWrapper } from '../FullHeightWrapper';
import { IMenuButtonProps } from './MenuButton.types';

export const MenuButton: React.FC<IMenuButtonProps> = ({
	className,
	isDark = false,
}: IMenuButtonProps): ReactElement => {
	const { buttonMenu, buttonClose } = useButtons();
	const { isMenuOpen, setIsMenuOpen, isMenuButtonDark } = useAppContext();

	const handleClick = (e: MouseEvent) => {
		e.stopPropagation();
		setIsMenuOpen(true);
	};

	const handleClose = (e: MouseEvent) => {
		e.stopPropagation();
		// Handled in script
	};

	return (
		<div
			className={cx(
				'c-menu-button',
				className,
				isMenuOpen && 'c-menu-button--hide',
			)}>
			<FullHeightWrapper className="c-menu-button__container">
				<Button
					label={buttonClose?.label}
					accessibilityLabel={buttonClose?.accessibilityLabel}
					type={BUTTON_TYPES.MEDIUM}
					onClickButton={handleClose}
					className={cx(
						'c-button--close c-button--base-hover c-button--hide',
						(isMenuButtonDark || isDark) && 'u-color-primary',
					)}
				/>
				<Button
					label={buttonMenu?.label}
					accessibilityLabel={buttonMenu?.accessibilityLabel}
					type={BUTTON_TYPES.MEDIUM}
					onClickButton={handleClick}
					className={cx(
						'c-button--base-hover js-button-menu',
						(isMenuButtonDark || isDark) && 'u-color-primary',
					)}
				/>
			</FullHeightWrapper>
		</div>
	);
};
