import * as THREE from 'three';

import { IWindow } from '../../types/window.types';
import { detectSafari } from '../../utils/detectBrowser.util';

class Renderer {
	public renderer: THREE.WebGLRenderer;
	public canvas: HTMLCanvasElement;

	private sizes: IWindow;

	constructor(canvas: HTMLCanvasElement, sizes: IWindow) {
		this.sizes = sizes;
		this.canvas = canvas;

		this.renderer = new THREE.WebGLRenderer({
			antialias: false,
			alpha: false,
			canvas: canvas,
			powerPreference: detectSafari() ? 'high-performance' : 'default',
		});
		this.renderer.setSize(this.sizes.width, this.sizes.height);
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	}

	update(sizes: IWindow): void {
		this.sizes = sizes;
		this.renderer.setSize(this.sizes.width, this.sizes.height);
	}

	render(scene: THREE.Scene, camera: THREE.Camera): void {
		this.renderer.render(scene, camera);
	}
}

export default Renderer;
