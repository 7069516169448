import React, { ReactElement } from 'react';

import { Link } from 'gatsby';

import { ROUTES } from '../../const/routes.const';
import { useButtons } from '../../hooks/data/buttons.hook';
import useLandingData from '../../hooks/data/landingPage.hook';
import { Button, BUTTON_TYPES } from '../Button';
import { Carousel } from '../Carousel/Carousel.component';
import { Logo } from '../Logo';
import { LandingPageProps } from './LandingPage.types';

export const LandingPage: React.FC<LandingPageProps> = ({
	handleOnClick,
}): ReactElement => {
	const { buttonClose } = useButtons();
	const images = useLandingData();

	return (
		<section className="c-landing-page u-bg-primary">
			<header className="c-landing-page__header">
				<Link
					to={ROUTES.HOME}
					onClick={handleOnClick}
					className="c-link c-link--none">
					<h1 className="c-logo--small">
						<span className="u-hide-visually">Raf Simons</span>
						<Logo />
					</h1>
				</Link>
			</header>

			<Carousel className="c-landing-page__carousel" images={images} />

			<footer className="c-landing-page__footer">
				<Button
					label={buttonClose?.label}
					accessibilityLabel={buttonClose?.accessibilityLabel}
					type={BUTTON_TYPES.MEDIUM}
					onClickButton={handleOnClick}
					className="u-color-secondary c-button--contrast-hover"
				/>
			</footer>
		</section>
	);
};
