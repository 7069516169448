import gsap from 'gsap';

import { ANIMATION_HELPERS, ANIMATION_TYPE } from '../../const/animations.const';
import { DEVICES } from '../../const/devices.const';
import { PERF } from '../../const/perf.const';
import { detectMobileOrTablet } from '../../utils/detectMobileTablet.util';
import { getPerf } from '../../utils/detectPerf.util';

export const BLUR_MAX = 0.05;
export const BLUR_MIN = 0.0;
const perf = getPerf();

export const handleBlur = (mesh: THREE.Mesh, type: ANIMATION_TYPE): void => {
	if (detectMobileOrTablet() !== DEVICES.DESKTOP || perf < PERF.PERF_GOOD) {
		return;
	}

	const uniforms = (mesh.material as THREE.ShaderMaterial).uniforms;

	if (uniforms && uniforms.uBlurStrength) {
		const updatedBlur = {
			value: type === ANIMATION_TYPE.IN ? BLUR_MIN : BLUR_MAX,
		};
		gsap.to(updatedBlur, {
			value: type === ANIMATION_TYPE.IN ? BLUR_MAX : BLUR_MIN,
			duration: ANIMATION_HELPERS.durationSmall,
			ease: ANIMATION_HELPERS.ease,
			onUpdate: () => {
				uniforms.uBlurStrength.value = updatedBlur.value;
			},
		});
	}
};
