/**
 * Tile version - Video
 * Fill a tile with an video element, works almost the same as an image
 */

import * as THREE from 'three';

import { ISize } from '../../types/grid.types';
import { detectSafari } from '../../utils/detectBrowser.util';
import getWindowSize from '../../utils/getWindow.util';
import { isIntroEnabled } from '../../utils/isIntroEnabled';
import { BLUR_MAX } from '../animations/blur.animations';
import fragment from '../shaders/blur/fragment.glsl';
import vertex from '../shaders/blur/vertex.glsl';
import Camera from './camera.class';

class Video {
	public id: string;
	public link: string;

	private video: HTMLVideoElement = document.createElement('video');
	private uniforms: { [key: string]: { value: any } };
	private url: string = '';
	private aspectRatio = 1;
	private mesh: THREE.Mesh;
	private size: ISize = { width: 0, height: 0 };
	private window = getWindowSize();
	private isMain = false;
	private camera: Camera;
	private isSafari = detectSafari();

	constructor(
		mesh: THREE.Mesh,
		id: string,
		link: string,
		camera: Camera,
		isMain?: boolean,
	) {
		this.id = id;
		this.link = link; // Safari fallback
		this.mesh = mesh;
		this.mesh.userData.isVideoLoading = true;
		this.camera = camera;
		this.getSource();

		if (isMain) {
			this.isMain = isMain;
		}

		this.uniforms = {
			uTexture: {
				value: null,
			},
			uBlurStrength: {
				value: isMain ? 0 : BLUR_MAX,
			},
			uResolution: {
				value: [this.window.width, this.window.height, 0],
			},
			uOpacity: {
				value: 1.0,
			},
		};
	}

	setUrl(json: any): void {
		if (!json.files) {
			return;
		}

		const files = json.play;
		this.url = this.isSafari
			? this.link
			: files.progressive[files.progressive.length - 1].link;
		this.aspectRatio = json.height / json.width;
	}

	setSize() {
		const textureIsHorizontal = this.aspectRatio < 1;

		// Info(Katia) Fit texture in mesh shape
		this.size = {
			width: textureIsHorizontal
				? this.mesh.scale.x
				: this.mesh.scale.y / this.aspectRatio,
			height: textureIsHorizontal
				? this.mesh.scale.x * this.aspectRatio
				: this.mesh.scale.y,
		};

		if (this.size.width > this.mesh.scale.x) {
			this.size = {
				width: this.mesh.scale.x,
				height: this.mesh.scale.x * this.aspectRatio,
			};
		}

		if (this.size.height > this.mesh.scale.y) {
			this.size = {
				width: this.mesh.scale.y * this.aspectRatio,
				height: this.mesh.scale.y,
			};
		}
	}

	updateScale(): void {
		this.mesh.scale.set(this.size.width, this.size.height, 1);
		this.mesh.userData.size = this.size;
	}

	getSource(): void {
		fetch(`https://api.vimeo.com/videos/${this.id}`, {
			headers: {
				Authorization: `Bearer ${process.env.VIMEO_TOKEN}`,
				Accept: 'application/json',
			},
		})
			.then((res) => res.json())
			.then((json) => {
				this.setUrl(json);
				this.setSize();
				this.generateVideo();
				this.generateTexture();
			});
	}

	generateVideo() {
		this.video.src = this.url;
		this.video.autoplay = true;
		this.video.controls = false;
		this.video.muted = true;
		this.video.playsInline = true;
		this.video.crossOrigin = 'anonymous';
	}

	generateTexture() {
		if (!this.video) {
			return;
		}

		// Remove previous material in memory
		(this.mesh.material as THREE.Material)?.dispose();

		// Add new material
		const texture = new THREE.VideoTexture(this.video);
		this.uniforms.uTexture.value = texture;

		// // Info(Katia): Update material with shader
		this.mesh.material = new THREE.ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader: vertex,
			fragmentShader: fragment,
			transparent: false,
			precision: 'lowp',
		});
		this.mesh.userData.isVideoLoaded = true;
		this.mesh.userData.isVideoLoading = false;
		this.mesh.userData.video = this.video;

		this.video.play();

		if (isIntroEnabled && this.isMain) {
			this.mesh.position.z = 2;
			this.mesh.scale.set(this.size.width, this.size.height, 1);
			this.mesh.userData.size = this.size;
		} else {
			this.updateScale();
		}

		this.video.addEventListener('loadeddata', () => {
			if (this.video.readyState >= 2) {
				// Camera start zoom
				this.camera.handleTimeout();
			}
		});
	}
}

export default Video;
