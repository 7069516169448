import { useStaticQuery, graphql } from 'gatsby';

import { IImage } from '../../types/data.types';

export const useLandingData = (): IImage[] => {
	const data = useStaticQuery(graphql`
		query {
			craft {
				page: entry(section: "home") {
					... on Craft_home_home_Entry {
						images {
							... on Craft_assetsAws_Asset {
								title
								id
								url
								urlHQ: url(width: 1800, format: "webp")
								urlHQFallback: url(width: 1800, format: "jpg")
							}
						}
					}
				}
			}
		}
	`);

	return data.craft.page.images;
};

export default useLandingData;
