import React, { ReactElement } from 'react';

import { ITabItem, ITabsProps } from './Tabs.types';

export const Tabs: React.FC<ITabsProps> = ({
	tabs,
	onClickTab,
}: ITabsProps): ReactElement => (
	<ul className="c-tabs u-flex u-jc-between">
		{tabs.map(({ label, id }: ITabItem) => (
			<li className="c-tabs__item" key={id}>
				<button className="c-tabs__link" onClick={() => onClickTab(id)}>
					{label}
				</button>
			</li>
		))}
	</ul>
);
