export enum CONTENT_BLOCK_TYPES {
	IMAGES = 'images',
	VIDEO = 'video',
	RICHTEXT = 'description',
}

export enum GRID_CONTENT_BLOCK_TYPES {
	IMAGE = 'image',
	VIDEO = 'video',
	AUDIO = 'audio',
	TEXT = 'text',
}

export enum CRAFT_SECTIONS {
	HOME = 'home',
	COLLECTIONS = 'collections',
	ABOUT = 'about',
	DEFAULT = 'default',
}
