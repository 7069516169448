import React, { ReactElement } from 'react';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { ANIMATION_HELPERS } from '../../const/animations.const';
import { ITransitionLinkProps } from './TransitionLink.types';

export const TransitionLink: React.FC<ITransitionLinkProps> = ({
	to,
	className,
	children,
	onClick,
}): ReactElement => (
	<AniLink
		onClick={onClick}
		cover
		direction="up"
		duration={ANIMATION_HELPERS.duration}
		bg="#000000"
		to={to}
		className={className}>
		{children}
	</AniLink>
);
