import React from 'react';
import { version } from './package.json';

import './src/styles/main.scss';

import { AppProvider } from './src/context/app/app.provider';
import { Layout } from './src/layouts';
import { gsap, CSSPlugin } from 'gsap/all';
import { detectPerf } from './src/utils/detectPerf.util';
import { detectWebpSupport } from './src/utils/supportsWebp.util';

export const onClientEntry = () => {
	detectPerf();
	detectWebpSupport();
	gsap.registerPlugin(CSSPlugin);
};

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;
export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>;

// Show version on stagings
if (window.location.href.includes('hyperdrive.studio')) {
	console.info('INFO: version', version);
}

const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const { pathname } = location;
	window.scrollTo(0, 0);

	return false;
};
