import React, { ReactElement } from 'react';

import Div100vh from 'react-div-100vh';

import { IFullHeightWrapperProps } from './FullHeightWrapper.types';

export const FullHeightWrapper: React.FC<IFullHeightWrapperProps> = ({
	className,
	children,
}: IFullHeightWrapperProps): ReactElement => (
	<Div100vh className={className}>{children}</Div100vh>
);
