import { lerp } from 'three/src/math/MathUtils';

import { DEVICES } from '../../const/devices.const';
import { ICoordinate } from '../../types/window.types';
import { detectMobileOrTablet } from '../../utils/detectMobileTablet.util';
import getWindowSize from '../../utils/getWindow.util';
import { isIntroEnabled } from '../../utils/isIntroEnabled';
import { getDistanceBetween } from '../utils/math';
import Wheel from './wheel.event';

class Drag {
	public current = {
		x: 0,
		y: 0,
	};
	public isClick = false;
	public normalized = {
		x: 0,
		y: 0,
	};
	public isEnabled = true;
	public isDragging = false;

	private isDown = false;
	private isMoved = false;
	private position = {
		x: 0,
		y: 0,
	};
	private target = {
		x: 0,
		y: 0,
	};
	private start = {
		x: 0,
		y: 0,
	};
	private initialPinch: number = 0;
	private isPinch: boolean = false;
	private pinchSpeed: number = 0.06;
	private device = detectMobileOrTablet();
	private ease = 0.05;
	private sizes = getWindowSize();
	private canvas: HTMLCanvasElement;
	private timeout: NodeJS.Timeout | null = null;
	private wheel: Wheel;
	private hasListeners = false;
	private handleClick: () => void;

	constructor(canvas: HTMLCanvasElement, wheel: Wheel, handleClick: () => void) {
		this.canvas = canvas;
		this.wheel = wheel;
		this.handleClick = handleClick;

		if (isIntroEnabled) {
			this.setIsEnabled(false);
		}

		this.setListeners();
	}

	setListeners() {
		if (this.hasListeners || !this.isEnabled) {
			return;
		}

		this.canvas.addEventListener('mousedown', this.onTouchDown.bind(this));
		this.canvas.addEventListener('mousemove', this.onTouchMove.bind(this));
		this.canvas.addEventListener('mouseup', this.onTouchUp.bind(this));

		this.canvas.addEventListener('touchstart', this.onTouchDown.bind(this));
		this.canvas.addEventListener('touchmove', this.onTouchMove.bind(this));
		this.canvas.addEventListener('touchend', this.onTouchUp.bind(this));

		this.hasListeners = true;
	}

	unsetListeners() {
		if (this.isEnabled) {
			return;
		}

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.canvas.removeEventListener('mousedown', this.onTouchDown.bind(this));
		this.canvas.removeEventListener('mousemove', this.onTouchMove.bind(this));
		this.canvas.removeEventListener('mouseup', this.onTouchUp.bind(this));

		this.canvas.removeEventListener('touchstart', this.onTouchDown.bind(this));
		this.canvas.removeEventListener('touchmove', this.onTouchMove.bind(this));
		this.canvas.removeEventListener('touchend', this.onTouchUp.bind(this));

		this.hasListeners = false;
	}

	// Info(Katia): Prevent crazy fast movements by limiting the amount of movement
	limitDistance(distance: { x: number; y: number }): { x: number; y: number } {
		if (distance.x > this.sizes.width / 2) {
			distance.x = this.sizes.width / 2;
		}

		if (distance.x < (this.sizes.width / 2) * -1) {
			distance.x = (this.sizes.width / 2) * -1;
		}

		if (distance.y > this.sizes.height / 2) {
			distance.y = this.sizes.height / 2;
		}

		if (distance.y < (this.sizes.height / 2) * -1) {
			distance.y = (this.sizes.height / 2) * -1;
		}

		return distance;
	}

	setNormalized(x: number, y: number): void {
		// Info(Katia): Used for touch/click event
		const normal = {
			x: (x / this.sizes.width) * 2 - 1,
			y: -((y / this.sizes.height) * 2 - 1),
		};
		this.normalized = {
			x: Math.round(normal.x * 100) / 100,
			y: Math.round(normal.y * 100) / 100,
		};
	}

	handleDrag(x: number, y: number): void {
		// Info(Katia): Drag logic
		if (!this.isDown) {
			return;
		}

		this.isDragging = true;
		let distance = {
			x: this.start.x - x,
			y: this.start.y - y,
		};

		distance = this.limitDistance(distance);

		if (this.device === DEVICES.MOBILE) {
			this.target = {
				x: this.position.x + distance.x * 2,
				y: this.position.y + distance.y * 2,
			};
		} else {
			this.target = {
				x: this.position.x + distance.x,
				y: this.position.y + distance.y,
			};
		}

		this.canvas.classList.add('is-dragging');
	}

	onTouchDown(event: TouchEvent | MouseEvent) {
		event.preventDefault();
		const touchEvent = event as TouchEvent;

		this.isDown = true;
		this.isMoved = false;

		this.position = { ...this.current };
		this.start = {
			x: (event as any).touches
				? touchEvent.touches[0].clientX
				: (event as MouseEvent).clientX,
			y: (event as any).touches
				? touchEvent.touches[0].clientY
				: (event as MouseEvent).clientY,
		};

		// Info(Silke): Initial pinch to touch value
		if (touchEvent?.touches?.length === 2) {
			const { touch1, touch2 } = this.getPinchTouches(touchEvent);
			const diff = getDistanceBetween(touch1, touch2);

			this.initialPinch = diff;
			this.isPinch = true;
		}

		this.setNormalized(this.start.x, this.start.y);
	}

	onTouchMove(event: TouchEvent | MouseEvent): void {
		event.preventDefault();

		const touchEvent = event as TouchEvent;

		if (touchEvent?.touches?.length === 2) {
			this.isPinch = true;
			this.handlePinchToZoom(touchEvent);
		}

		if (!this.isEnabled || this.isPinch) {
			return;
		}

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		const x = (event as any).touches
			? touchEvent.touches[0].clientX
			: (event as MouseEvent).clientX;
		const y = (event as any).touches
			? touchEvent.touches[0].clientY
			: (event as MouseEvent).clientY;

		// Info(Katia): Used for click event
		this.setNormalized(x, y);

		if (x === this.start.x && y === this.start.y) {
			// Click event
			return;
		}

		this.isMoved = true;
		this.handleDrag(x, y);

		// Info(Katia): Stop moving when you keep pushing down and not moving
		this.timeout = setTimeout(() => {
			this.onTouchUp();
			// Prevent double up function
			this.isMoved = true;
		}, 500);
	}

	onTouchUp(): void {
		this.initialPinch = 0;

		// Stop timeout from calling ontouchup
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		if (!this.isMoved && !this.isPinch && this.isEnabled) {
			this.handleClick();
		}

		if (this.isMoved) {
			this.isDragging = false;
		}

		this.isPinch = false;
		this.isDown = false;
		this.isMoved = false;
		this.canvas.classList.remove('is-dragging');
	}

	setIsEnabled(isEnabled: boolean): void {
		if (this.isEnabled === isEnabled) {
			return;
		}

		this.isEnabled = isEnabled;

		if (isEnabled) {
			this.setListeners();
			this.canvas.classList.remove('is-disabled');
		} else if (!isEnabled) {
			this.unsetListeners();
			this.canvas.classList.add('is-disabled');
		}
	}

	handlePinchToZoom(event: TouchEvent): void {
		this.wheel.updateTarget();

		const { touch1, touch2 } = this.getPinchTouches(event);
		const diff = getDistanceBetween(touch1, touch2);
		const scale = diff / this.initialPinch;

		if (scale > 1) {
			this.wheel.zoomIn(this.pinchSpeed);
		}

		if (scale < 1) {
			this.wheel.zoomOut(this.pinchSpeed);
		}

		this.wheel.updateCamera();
	}

	getPinchTouches(event: TouchEvent): {
		touch1: ICoordinate;
		touch2: ICoordinate;
	} {
		const touch1: ICoordinate = {
			x: event.touches[0].clientX,
			y: event.touches[0].clientY,
		};
		const touch2: ICoordinate = {
			x: event.touches[1].clientX,
			y: event.touches[1].clientY,
		};

		return { touch1, touch2 };
	}

	update(): void {
		if (this.isEnabled && !this.isPinch) {
			this.canvas.classList.remove('is-disabled');
		} else {
			this.canvas.classList.add('is-disabled');
		}

		if (
			!this.isEnabled ||
			this.isPinch ||
			(this.current.x === this.target.x && this.current.y === this.target.y)
		) {
			return;
		}

		this.current.x = Math.round(lerp(this.current.x, this.target.x, this.ease));
		this.current.y = Math.round(lerp(this.current.y, this.target.y, this.ease));
	}

	reinit(canvas: HTMLCanvasElement): void {
		this.setIsEnabled(false);

		// Initialize again
		this.canvas = canvas;
		this.setIsEnabled(true);
		this.canvas.classList.remove('is-disabled');
	}

	resize(): void {
		this.sizes = getWindowSize();
	}
}

export default Drag;
