import shuffleArray from './shuffleArray.util';

export const getRandomNumber = (min: number, max: number): number =>
	Math.floor(Math.random() * (max - min + 1)) + min;

export const getRandomEnum = <T>(enumObj: T): T[keyof T] => {
	const enumValues = Object.values(enumObj);
	const index = Math.floor(Math.random() * enumValues.length);

	return enumValues[index];
};

export const getRandomArrayValue = (array: any[]) => {
	const shuffledArray = shuffleArray(array);
	return shuffledArray[0];
};
