import React, { ReactElement } from 'react';

import cx from 'classnames';
import { Link } from 'gatsby';

import { ROUTES } from '../../const/routes.const';
import { Logo } from '../Logo';
import { TransitionLink } from '../TransitionLink';
import { HEADER_TYPES } from './Header.const';
import { IHeaderProps } from './Header.types';

export const Header: React.FC<IHeaderProps> = ({
	type = HEADER_TYPES.Light,
	className,
	disableTransition = false,
}: IHeaderProps): ReactElement => {
	return (
		<header className={cx(`c-header c-header--${type}`, className)}>
			<div className="u-grid u-cols-3">
				<div className="u-as-center u-flex u-jc-center u-col-start-1 u-col-3">
					<h1 className={`c-logo--small c-logo--${type}`}>
						<span className="u-hide-visually">Raf Simons</span>
						{disableTransition ? (
							<Link to={ROUTES.HOME} className="c-link c-link--none">
								<Logo />
							</Link>
						) : (
							<TransitionLink
								to={ROUTES.HOME}
								className="c-link c-link--none">
								<Logo />
							</TransitionLink>
						)}
					</h1>
				</div>
			</div>
		</header>
	);
};
