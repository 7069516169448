import * as THREE from 'three';
import { Text as TextGeometry } from 'troika-three-text';

import { BACKGROUND } from '../../const/background.const';
import { IGridText } from '../../types/grid.types';
import { isIntroEnabled } from '../../utils/isIntroEnabled';

class Text {
	private mesh: THREE.Mesh;
	private text: string;
	private color = 0x000000;
	private background: BACKGROUND;
	private padding = 5;
	private group: THREE.Group = new THREE.Group();
	private parent: THREE.Group;
	private fontSize = 40;
	private fontSizeSmall = 20;
	private letterSpacing = 0.14;
	private letterSpacingSmall = 0.14;
	private maxWidth: number;
	private hasLink = false;
	private textGeo: TextGeometry;

	constructor(mesh: THREE.Mesh, item: IGridText) {
		this.mesh = mesh;
		this.parent = mesh.parent as THREE.Group;
		this.text = item.text;
		this.background = item.background;
		this.padding = 0.08 * this.mesh.scale.x;
		this.fontSize = 0.1 * this.mesh.scale.x;
		this.fontSizeSmall = 0.05 * this.mesh.scale.x;
		this.letterSpacing = 0.001 * this.fontSize;
		this.letterSpacingSmall = 0.001 * this.fontSizeSmall;
		this.maxWidth = this.mesh.scale.x - 2 * this.padding;
		this.hasLink =
			item.link?.external !== null || item.link?.internal !== undefined;

		if (this.background === BACKGROUND.NONE) {
			this.color = 0xffffff;
		}

		this.setText();

		this.parent.add(this.group);
		this.group.userData = this.mesh.userData;
	}

	getBounding(item: THREE.Mesh): { width: number; height: number } {
		const box = (item.geometry as THREE.BufferGeometry)
			.boundingBox as THREE.Box3;
		return {
			width: box.max.x - box.min.x,
			height: box.max.y - box.min.y,
		};
	}

	setBackground() {
		if (this.background === BACKGROUND.NONE) {
			return;
		}

		// Info(Katia): Can't set color directly because it is not a copy of the reused material
		(this.mesh.material as THREE.Material).dispose();
		this.mesh.material = new THREE.MeshBasicMaterial({
			color: this.background === BACKGROUND.WHITE ? '#ffffff' : '#ffff00',
			opacity: 1,
			transparent: true,
		});

		if (isIntroEnabled) {
			this.mesh.visible = false;
		}
	}

	setText() {
		this.textGeo = new TextGeometry();
		this.group.add(this.textGeo);

		// Set properties to configure:
		this.textGeo.text = this.text.toUpperCase();
		this.textGeo.letterSpacing = this.letterSpacing;
		this.textGeo.fontSize = this.fontSize;
		this.textGeo.anchorY = 'middle';
		this.setFontSettings(this.textGeo);

		// Set Position
		this.textGeo.position.x =
			this.mesh.position.x - this.mesh.scale.x / 2 + this.padding;
		this.textGeo.position.y = this.mesh.position.y + this.padding / 2;
		this.textGeo.position.z = 1;

		// Add userdata
		this.textGeo.userData = {
			...this.mesh.userData,
			position: new THREE.Vector3().copy(this.textGeo.position),
			size: null,
		};

		// Update the rendering:
		this.textGeo.sync();
		this.textGeo.addEventListener('synccomplete', () => {
			this.setLink();
		});

		if (isIntroEnabled) {
			this.textGeo.visible = false;
		}
	}

	setLink() {
		const sizesText = this.getBounding(this.textGeo);
		const y = this.textGeo.position.y - sizesText.height / 2 - this.padding;
		if (!this.hasLink) {
			this.setScale(sizesText.height);
			return;
		}

		const linkGeo = new TextGeometry();
		this.group.add(linkGeo);

		// Set properties to configure:
		linkGeo.text = 'VISIT LINK';
		linkGeo.letterSpacing = this.letterSpacingSmall;
		linkGeo.fontSize = this.fontSizeSmall;

		// Set Position
		linkGeo.position.x =
			this.mesh.position.x - this.mesh.scale.x / 2 + this.padding;
		linkGeo.position.y = y;
		linkGeo.position.z = 1;

		// Add userdata
		linkGeo.userData = {
			...this.mesh.userData,
			position: new THREE.Vector3().copy(linkGeo.position),
			size: null,
		};
		this.setFontSettings(linkGeo);

		// Update the rendering:
		linkGeo.sync();
		linkGeo.addEventListener('synccomplete', () => {
			const sizes = this.getBounding(linkGeo);
			const y = linkGeo.position.y - sizes.height;
			this.setLine(sizes.width, y);
			this.setScale(
				sizesText.height + sizes.height + this.padding + this.padding,
				this.padding / 2,
			);
		});

		if (isIntroEnabled) {
			linkGeo.visible = false;
		}
	}

	// Info(Katia): Underline under link
	setLine(width: number, y: number) {
		const line = new THREE.PlaneBufferGeometry(1, 1, 20, 20);
		const material = new THREE.MeshBasicMaterial({
			color: this.color,
			transparent: true,
		});
		const mesh = new THREE.Mesh(line, material);
		mesh.scale.set(width, 1, 1);
		mesh.position.set(
			this.mesh.position.x - this.mesh.scale.x / 2 + this.padding + width / 2,
			y,
			2,
		);
		mesh.userData = {
			...this.mesh.userData,
			position: new THREE.Vector3().copy(mesh.position),
			size: {
				width: mesh.scale.x,
				height: mesh.scale.y,
			},
		};

		if (isIntroEnabled) {
			mesh.visible = false;
		}

		this.group.add(mesh);
	}

	setScale(height: number, y: number = 0) {
		this.mesh.scale.y = height + this.padding;
		this.mesh.position.y = this.mesh.position.y - y;
		this.mesh.userData.size = {
			width: this.mesh.scale.x,
			height: this.mesh.scale.y,
		};
		this.mesh.userData.position = new THREE.Vector3().copy(this.mesh.position);
		this.setBackground();
	}

	setFontSettings(geo: TextGeometry) {
		if (typeof window !== 'undefined') {
			geo.font = `${window.location.origin}/fonts/HelveticaNeue-CondensedBold.woff`;
		}

		geo.color = this.color;
		geo.maxWidth = this.maxWidth;
	}
}

export default Text;
