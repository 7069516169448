import {
	IGridImageOffset,
	IGridImageScale,
	IGridImageSize,
} from '../../types/grid.types';
import { map } from './math';

const FOCAL_MIN = 0;
const FOCAL_MAX = 1;

export const calculateImageSizeWithFocalPoint = (
	hasFocalPoint: boolean,
	focalPoint: number[] | null,
	originalImageSize: IGridImageSize,
): IGridImageSize => {
	if (!hasFocalPoint || !focalPoint || !focalPoint.length) {
		return originalImageSize;
	}

	const focalPointX = focalPoint[0];
	const focalPointY = focalPoint[1];

	// Note(Silke): Calculate the center of the focal point range
	const focalCenter = FOCAL_MAX / 2;

	// Note(Silke): Get the distance between the focal point and the nearest side
	const focalPosX =
		focalPointX >= focalCenter ? FOCAL_MAX - focalPointX : focalPointX;
	const focalPosY =
		focalPointY >= focalCenter ? FOCAL_MAX - focalPointY : focalPointY;

	// Note(Silke): get biggest image possible while keeping the focal point centered
	const width = Math.abs(focalPosX) * 2 * originalImageSize.width;
	const height = Math.abs(focalPosY) * 2 * originalImageSize.height;

	return {
		width,
		height,
	};
};

export const calculateImageScaleWithFocalPoint = (
	hasFocalPoint: boolean,
	focalPoint: number[] | null,
	originalImageSize: IGridImageSize,
	croppedImageSize: IGridImageSize,
	originalImageScale: IGridImageScale,
): IGridImageScale => {
	if (!hasFocalPoint || !focalPoint || !focalPoint.length) {
		return {
			x: originalImageScale.x,
			y: originalImageScale.y,
		};
	}

	// Info(Silke): Calculate how much smaller the cropped image is compared to the original
	const ratioX = croppedImageSize.width / originalImageSize.width;
	const ratioY = croppedImageSize.height / originalImageSize.height;

	// Info(Silke): multiply ratio with original scale to know the cropped image scale
	const scaleX = ratioX * originalImageScale.x;
	const scaleY = ratioY * originalImageScale.y;

	return {
		x: scaleX,
		y: scaleY,
	};
};

export const getFocalPointOffset = (
	hasFocalPoint: boolean,
	focalPoint: number[] | null,
	scaleX: number,
	scaleY: number,
): IGridImageOffset => {
	if (!hasFocalPoint || !focalPoint || !focalPoint.length) {
		return {
			offsetX: 0,
			offsetY: 0,
		};
	}

	// Info(Silke): Map focal point between -1 & 1 which makes it easier to multiply with
	const x = map(focalPoint[0], FOCAL_MIN, FOCAL_MAX, -0.5, 0.5);
	const y = map(focalPoint[1], FOCAL_MIN, FOCAL_MAX, -0.5, 0.5);

	return {
		offsetX: x * scaleX,
		offsetY: y * scaleY,
	};
};
