import React, {
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import { useLocation } from '@reach/router';
import cx from 'classnames';

import { ROUTES } from '../../const/routes.const';
import { IAppContext } from '../../context/app/app.types';
import { useAppContext } from '../../hooks/context/appContext.hook';
import { useButtons } from '../../hooks/data/buttons.hook';
import { useNavigation } from '../../hooks/data/navigation.hook';
import { INavCollection, INavigation } from '../../types/data.types';
import { isBrowser } from '../../utils/isBrowser.util';
import { Button, BUTTON_TYPES } from '../Button';
import { FullHeightWrapper } from '../FullHeightWrapper';
import { Logo } from '../Logo';
import { TransitionLink } from '../TransitionLink';
import {
	animateCollectionsIn,
	animateCollectionsOut,
	animateIn,
	animateOut,
} from './Navigation.animations';

export const Navigation: React.FC = (): ReactElement => {
	const navRef = useRef<HTMLElement | null>(null);
	const collectionsRef = useRef<HTMLUListElement | null>(null);
	const collectionRef = useRef<HTMLLIElement | null>(null);

	const [showCollections, setShowCollections] = useState<boolean>(false);

	const { pathname } = useLocation();
	const { buttonBack, buttonClose } = useButtons();

	const {
		navigation: { navigationCollections, navigationShop, navigationAbout },
		collections,
	}: INavigation = useNavigation();
	const { isMenuOpen, setIsMenuOpen }: IAppContext = useAppContext();

	const handleClose = useCallback((): void => {
		setShowCollections(false);
		setIsMenuOpen(false);
	}, [setIsMenuOpen, setShowCollections]);

	const handleEsc = useCallback(
		(e: KeyboardEvent): void => {
			if (e.code === 'Escape') {
				handleClose();
			}
		},
		[handleClose],
	);

	const toggleShowCollections = (): void => {
		setShowCollections((prev: boolean) => !prev);
	};

	const handleOnClickNavItem = (to: string): void => {
		if (pathname === to) {
			handleClose();
		}
	};

	const handleScrollCollections = () => {
		collectionsRef?.current?.parentElement?.classList.add('has-scrolled');
		collectionsRef?.current?.removeEventListener(
			'scroll',
			handleScrollCollections,
		);
	};

	useEffect(() => {
		if (isBrowser) {
			window.addEventListener('keyup', handleEsc);
			collectionsRef?.current?.addEventListener(
				'scroll',
				handleScrollCollections,
			);
		}

		return () => {
			if (isBrowser) {
				window.removeEventListener('keyup', handleEsc);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleClose();
	}, [pathname, handleClose]);

	useEffect(() => {
		const $navContainer = navRef?.current;

		if ($navContainer) {
			isMenuOpen ? animateIn($navContainer) : animateOut($navContainer);
		}
	}, [isMenuOpen]);

	useEffect(() => {
		const $collectionsContainer = collectionsRef?.current;
		const $navContainer = navRef?.current;

		if ($collectionsContainer && $navContainer) {
			showCollections
				? animateCollectionsIn($navContainer, $collectionsContainer)
				: animateCollectionsOut($navContainer, $collectionsContainer);
		}

		const $btn = collectionRef?.current?.querySelector(
			'.c-button',
		) as HTMLButtonElement;

		if (!showCollections && $btn) {
			$btn.blur();
		}
	}, [showCollections]);

	return (
		<nav ref={navRef} className={cx('c-nav u-bg-primary')}>
			<FullHeightWrapper>
				<ul className="c-nav__list">
					<li className="c-nav__item c-nav__item--logo">
						<div className="c-logo--small">
							<span className="u-hide-visually">Raf Simons</span>
							<TransitionLink
								to={ROUTES.HOME}
								onClick={() => handleOnClickNavItem(ROUTES.HOME)}>
								<div className="c-logo--light">
									<Logo />
								</div>
							</TransitionLink>
						</div>
					</li>

					<li
						ref={collectionRef}
						className="c-nav__item c-nav__item--collections">
						<Button
							className="c-nav__content"
							onClickButton={toggleShowCollections}
							type={BUTTON_TYPES.LARGE}
							label={navigationCollections?.label}
							accessibilityLabel={navigationCollections?.label}
						/>
					</li>

					<li className="c-nav__item c-nav__item--collections-list">
						<div className="c-nav__item--shadow">
							<ul
								ref={collectionsRef}
								className="c-nav__collections-list">
								{collections.map(
									({ slug, title }: INavCollection) => (
										<li key={slug} className="c-nav__item">
											<TransitionLink
												onClick={() =>
													handleOnClickNavItem(
														`/${ROUTES.COLLECTIONS}/${slug}`,
													)
												}
												className="c-nav__link c-nav__content"
												to={`/${ROUTES.COLLECTIONS}/${slug}`}>
												{title}
											</TransitionLink>
										</li>
									),
								)}
							</ul>
						</div>
					</li>

					<li className="c-nav__item c-nav__item--shop">
						<a
							className="c-nav__link c-nav__content"
							href={navigationShop?.shopUrl}
							aria-label={navigationShop?.accessibilityLabel}
							target="_blank"
							rel="noreferrer">
							{navigationShop?.label}
						</a>
					</li>

					<li className="c-nav__item c-nav__item--about">
						<TransitionLink
							onClick={() => handleOnClickNavItem(`/${ROUTES.ABOUT}`)}
							className="c-nav__link c-nav__content"
							aria-label={navigationAbout?.accessibilityLabel}
							to={`/${ROUTES.ABOUT}`}>
							{navigationAbout?.label}
						</TransitionLink>
					</li>

					<li className="c-nav__item c-nav__item--button">
						<Button
							className="c-button--contrast-hover"
							label={
								showCollections
									? buttonBack.label
									: buttonClose.label
							}
							accessibilityLabel={
								showCollections
									? buttonBack.accessibilityLabel
									: buttonBack.accessibilityLabel
							}
							onClickButton={
								showCollections ? toggleShowCollections : handleClose
							}
							type={BUTTON_TYPES.MEDIUM}
						/>
					</li>
				</ul>
			</FullHeightWrapper>
		</nav>
	);
};
