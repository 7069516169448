import React, { ReactElement } from 'react';

import cx from 'classnames';

import { TransitionLink } from '../TransitionLink';
import { ILink, ILinkListProps } from './LinkList.types';

export const LinkList: React.FC<ILinkListProps> = ({
	className,
	links,
}: ILinkListProps): ReactElement => (
	<ul className={cx('u-reset-list u-font-s-sm c-link-list', className)}>
		{links.map(({ label, to, isExternal }: ILink) => (
			<li key={to}>
				{isExternal ? (
					<a href={to} target="_blank" rel="noreferrer">
						{label}
					</a>
				) : (
					<TransitionLink className="c-link" to={`/${to}`}>
						{label}
					</TransitionLink>
				)}
			</li>
		))}
	</ul>
);
