import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

import { useSiteMetaData } from '../../hooks/data/siteMetaData.hook';
import { ISEOProps } from './SEO.types';

export const SEO: React.FC<ISEOProps> = ({
	metaTitleContainer,
	metaTagContainer,
	metaJsonLdContainer,
	metaLinkContainer,
}: ISEOProps): ReactElement => {
	const seo = useSiteMetaData();

	const jsonld = metaJsonLdContainer || seo.metaJsonLdContainer;
	const splitJsonLdStringIntoArray = jsonld
		.replace(/<\/script>/g, '')
		.split(`<script type="application/ld+json">`);

	return (
		<Helmet
			htmlAttributes={{
				lang: 'en',
			}}>
			<meta name="theme-color" content="#000000" />
			{ReactHtmlParser([
				metaTitleContainer || seo.metaTitleContainer,
				metaTagContainer || seo.metaTagContainer,
				metaLinkContainer || seo.metaLinkContainer,
			])}

			{splitJsonLdStringIntoArray.map((script, index) => (
				<script type="application/ld+json" key={`json-ld-${index}`}>
					{script}
				</script>
			))}
		</Helmet>
	);
};
