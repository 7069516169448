import React, { ReactElement, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import gsap from 'gsap';

import { ANIMATION_HELPERS } from '../../const/animations.const';
import { ROUTES } from '../../const/routes.const';
import { IPageInfo } from '../../types/data.types';
import { RichText } from '../RichText';
import { TransitionLink } from '../TransitionLink';
import { IAccordionItemProps, IAccordionProps } from './Accordion.types';

export const Accordion: React.FC<IAccordionProps> = ({
	className,
	items,
	onToggle,
}: IAccordionProps): ReactElement => (
	<dl className={cx('c-accordion', className)}>
		{items.map(({ title, content, id, careers }: IAccordionItemProps) => (
			<AccordionItem
				key={title}
				id={id}
				title={title}
				content={content}
				careers={careers}
				onToggle={onToggle}
			/>
		))}
	</dl>
);

const AccordionItem: React.FC<IAccordionItemProps> = ({
	className,
	title,
	content,
	careers,
	id,
	onToggle,
}: IAccordionItemProps): ReactElement => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const contentRef = useRef<HTMLElement | null>(null);

	const handleClick = (): void => {
		setIsOpen((prevIsOpen: boolean) => !prevIsOpen);
	};

	useEffect(() => {
		const $el = contentRef?.current;

		if (!$el) {
			return;
		}

		gsap.to($el, {
			height: isOpen ? 'auto' : 0,
			duration: ANIMATION_HELPERS.durationSmall,
			ease: ANIMATION_HELPERS.ease,
			onComplete: onToggle,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div
			key={id}
			className={cx(
				'c-accordion-item u-font-s-md',
				className,
				isOpen && 'c-accordion-item--open',
			)}>
			<dt
				className="c-accordion-item__title"
				data-collapsed={!isOpen}
				onClick={handleClick}>
				{title}
			</dt>
			<dd className="c-accordion-item__content" ref={contentRef}>
				<div className="c-accordion-item__inner">
					{content && (
						<RichText className="u-font-s-sm" content={content} />
					)}
					{careers && careers?.length > 0 && (
						<ul className="u-list-reset">
							{careers.map(({ title, slug }: IPageInfo) => (
								<li className=" u-font-s-sm" key={slug}>
									<TransitionLink
										className="c-link"
										to={`/${ROUTES.CAREERS}/${slug}`}>
										{title}
									</TransitionLink>
								</li>
							))}
						</ul>
					)}
				</div>
			</dd>
		</div>
	);
};
