import gsap from 'gsap';
import * as THREE from 'three';

import { ANIMATION_HELPERS } from '../../const/animations.const';
import { IWindow } from '../../types/window.types';
import { isIntroEnabled } from '../../utils/isIntroEnabled';
import Drag from '../events/drag.event';
import { lerp } from '../utils/lerp';

class Camera {
	public perspective = 1000;
	public DEFAULT_ZOOM = 1;
	public camera: THREE.PerspectiveCamera;
	public drag: Drag | undefined;

	private sizes: IWindow;
	private zoom: number = isIntroEnabled ? 6 : 1;
	private isInitZoom = true;
	private isReset = false;
	private EASE = 0.1;
	private MAX_ZOOM = 6;
	private MIN_ZOOM = 0.6;
	private INIT_ZOOM_DELAY = 3000;

	// Info(Katia): Same look on different screens
	private fov = 40;
	private timeout: NodeJS.Timeout | undefined;
	private callback: (items?: THREE.Group) => void;

	constructor(sizes: IWindow, callback: (items?: THREE.Group) => void) {
		this.sizes = sizes;

		this.camera = new THREE.PerspectiveCamera(
			this.fov,
			this.sizes.aspectRatio,
			0.1,
			10000,
		);

		this.camera.position.set(0, 0, this.perspective);
		this.callback = callback;
	}

	handleTimeout = () => {
		if (!isIntroEnabled) {
			return;
		}

		this.timeout = setTimeout(this.handleAutomaticZoom, this.INIT_ZOOM_DELAY);
	};

	handleAutomaticZoom = () => {
		if (this.zoom > 5) {
			this.resetZoom(
				{ value: this.zoom },
				0,
				this.callback,
				ANIMATION_HELPERS.durationXLarge,
			);

			this.drag?.setIsEnabled(true);

			if (this.timeout) {
				clearTimeout(this.timeout);
			}
		}
	};

	setDrag(drag: Drag) {
		this.drag = drag;
	}

	updateZoom(target: number): void {
		if (
			target >= this.MAX_ZOOM ||
			target <= this.MIN_ZOOM ||
			this.drag?.isDragging ||
			this.isReset
		) {
			return;
		}

		if (this.isInitZoom) {
			this.resetZoom(
				{ value: this.zoom },
				0,
				this.callback,
				ANIMATION_HELPERS.durationXLarge,
			);
			this.isInitZoom = false;
		} else {
			target = lerp(this.zoom, target, this.EASE);
			this.zoom = Math.min(Math.max(0.125, target), 4);
			this.drag?.setIsEnabled(true);
			this.callback();
		}

		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	resetZoom(
		target = { value: this.zoom },
		delay = 0,
		callback?: () => void,
		duration = ANIMATION_HELPERS.duration,
	): void {
		this.isReset = true;
		gsap.to(target, {
			value: this.DEFAULT_ZOOM,
			duration,
			ease: ANIMATION_HELPERS.ease,
			delay,
			onUpdate: () => {
				this.zoom = target.value;
				this.isReset = false;
				if (callback) {
					// Update visibility
					callback();
				}
			},
		});
	}

	resize(sizes: IWindow): void {
		this.sizes = sizes;

		if (sizes.aspectRatio) {
			this.camera.aspect = sizes.aspectRatio;
		}

		this.camera.updateProjectionMatrix();
	}

	update(): void {
		this.camera.zoom = this.zoom;
		this.camera.updateProjectionMatrix();
	}
}

export default Camera;
