import { useStaticQuery, graphql } from 'gatsby';

import {
	CONTENT_BLOCK_TYPES,
	GRID_CONTENT_BLOCK_TYPES,
} from '../../const/data.const';
import { IHighlight, IImage } from '../../types/data.types';
import { IGridContentItem } from '../../types/grid.types';
import getCollectionSlug from '../../utils/getCollectionSlug.util';
import shuffleArray from '../../utils/shuffleArray.util';

export const useGridData = (): {
	highlights: IGridContentItem[];
	extended: IGridContentItem[];
} => {
	const data = useStaticQuery(graphql`
		query {
			craft {
				page: entry(section: "home") {
					... on Craft_home_home_Entry {
						highlightedItems {
							... on Craft_highlightedItems_audio_BlockType {
								id
								typeHandle
								audioTitle
								audioArtist
								audioFile {
									... on Craft_assetsAws_Asset {
										url
									}
								}
							}
							... on Craft_highlightedItems_video_BlockType {
								id
								typeHandle
								videoId
								videoLink
							}
							... on Craft_highlightedItems_text_BlockType {
								id
								typeHandle
								text
								externalLink
								internalLink {
									id
									slug
									sectionHandle
								}
								background
							}
							... on Craft_highlightedItems_image_BlockType {
								id
								typeHandle
								image {
									width
									height
									... on Craft_assetsAws_Asset {
										title
										url
										urlHQ: url(width: 1800, format: "webp")
										urlNQ: url(width: 800, format: "webp")
										urlLQ: url(width: 400, format: "webp")
										urlHQFallback: url(
											width: 1800
											format: "jpg"
										)
										urlNQFallback: url(width: 800, format: "jpg")
										urlLQFallback: url(width: 400, format: "jpg")
										hasFocalPoint
										focalPoint
									}
								}
								externalLink
								internalLink {
									id
									sectionHandle
									slug
								}
							}
						}
					}
				}
				entries(section: "collections") {
					... on Craft_collections_collection_Entry {
						id
						slug
						sectionHandle
						contentBlocks {
							... on Craft_contentBlocks_images_BlockType {
								typeHandle
								images {
									... on Craft_assetsAws_Asset {
										id
										width
										height
										url
										urlHQ: url(width: 1800, format: "webp")
										urlNQ: url(width: 800, format: "webp")
										urlLQ: url(width: 400, format: "webp")
										urlHQFallback: url(
											width: 1800
											format: "jpg"
										)
										urlNQFallback: url(width: 800, format: "jpg")
										urlLQFallback: url(width: 400, format: "jpg")
										title
										hasFocalPoint
										focalPoint
									}
								}
							}
						}
						parent {
							slug
							parent {
								slug
								parent {
									slug
								}
							}
						}
					}
				}
			}
		}
	`);

	const highlights = data.craft.page.highlightedItems;
	const extendedItems = data.craft.entries;

	const formattedHighlightItems: IGridContentItem[] = [];
	const formattedExtendedItems: IGridContentItem[] = [];

	// Info(Silke): Format highlights
	highlights.forEach((highlight: IHighlight): void => {
		const { id, typeHandle: type } = highlight;
		const internalLink = highlight.internalLink?.[0];
		const link = {
			entryId: internalLink?.id,
			sectionHandle: internalLink?.sectionHandle,
			external: highlight.externalLink,
			internal: internalLink?.slug,
		};

		const defaultContent = {
			id,
			type,
			link,
		};

		switch (type) {
			case GRID_CONTENT_BLOCK_TYPES.IMAGE:
				const image = {
					...defaultContent,
					image: highlight.image?.[0],
				};

				formattedHighlightItems.push(image);
				break;
			case GRID_CONTENT_BLOCK_TYPES.TEXT:
				const text = {
					...defaultContent,
					text: highlight.text,
					background: highlight.background,
				};
				formattedHighlightItems.push(text);
				break;
			case GRID_CONTENT_BLOCK_TYPES.VIDEO:
				const video = {
					id: highlight.id,
					type: highlight.typeHandle,
					videoId: highlight.videoId,
					videoLink: highlight.videoLink,
				};
				formattedHighlightItems.push(video);
				break;

			case GRID_CONTENT_BLOCK_TYPES.AUDIO:
				const audio = {
					...defaultContent,
					audio: {
						title: highlight.audioTitle || '',
						url: highlight?.audioFile?.[0]?.url || '',
						...(highlight?.audioArtist && {
							artist: highlight?.audioArtist,
						}),
					},
				};

				formattedHighlightItems.push(audio);
				break;
		}
	});

	if (!extendedItems || !extendedItems.length) {
		return {
			highlights: formattedHighlightItems,
			extended: [],
		};
	}

	// Info(Silke): Format extended items
	for (const extendedItem of extendedItems) {
		if (extendedItem.contentBlocks.length > 0) {
			for (const block of extendedItem.contentBlocks) {
				// Info: BLOCK IMAGES
				if (block.typeHandle === CONTENT_BLOCK_TYPES.IMAGES) {
					const images = block.images.map((image: IImage) => ({
						id: image.id,
						type: GRID_CONTENT_BLOCK_TYPES.IMAGE,
						image,
						link: {
							entryId: extendedItem.id,
							sectionHandle: extendedItem.sectionHandle,
							internal: extendedItem.parent
								? getCollectionSlug(extendedItem.parent)
								: extendedItem.slug,
						},
					}));

					formattedExtendedItems.push(...images);
				}
			}
		}
	}

	return {
		highlights: formattedHighlightItems,
		extended: shuffleArray(formattedExtendedItems),
	};
};

export default useGridData;
