/**
 * Tile version - Audio
 * Fill a tile with an audio element
 */

import { Howl } from 'howler';
import * as THREE from 'three';
import { Text as TextGeometry } from 'troika-three-text';

import { IGridAudio } from '../../types/grid.types';
import { isIntroEnabled } from '../../utils/isIntroEnabled';

class Audio {
	private isPlaying: boolean = false;
	private howl: Howl | null = null;
	private audio: IGridAudio;
	private mesh: THREE.Mesh;
	private gridIndex: number;

	private color = 0xffffff;
	private fontSize = 16;
	private letterSpacing = 0.07;

	private button: THREE.Group = new THREE.Group();
	private playIcon: THREE.Group = new THREE.Group();
	private pauseIcon: THREE.Group = new THREE.Group();
	private group: THREE.Group = new THREE.Group();

	constructor(mesh: THREE.Mesh, audio: IGridAudio, gridIndex: number) {
		this.mesh = mesh;
		this.audio = audio;
		this.gridIndex = gridIndex;

		this.mesh.scale.set(30, 30, 1);
		this.mesh.userData.size = {
			width: this.mesh.scale.x,
			height: this.mesh.scale.y,
		};
		if (this.mesh.parent) {
			this.mesh.parent.add(this.group);
		}

		this.init();
		this.generateUserData(this.group);
	}

	private init() {
		const {
			audio: { artist, url, title },
		} = this.audio;

		this.howl = new Howl({
			src: [url],
			preload: true,
			html5: true,
			loop: true,
			volume: 0.7,
		});

		const material = new THREE.MeshBasicMaterial({
			color: 0xffffff,
			transparent: true,
		});
		this.drawPlayIcon(material);
		this.drawPauseIcon(material);
		this.button.add(this.playIcon, this.pauseIcon);
		this.generateUserData(this.button);
		this.toggleIcon();

		if (artist) {
			this.addArtist(artist);
		}

		if (title) {
			this.addTitle(title, artist !== undefined);
		}

		// Info(Silke): Add to grid
		this.mesh.add(this.button);
		this.mesh.userData.isAudio = true;
		if (isIntroEnabled) {
			this.mesh.visible = false;
		}
	}

	private drawPlayIcon(material: THREE.MeshBasicMaterial): void {
		// Info(Silke): Play icon (= triangle)
		const playGeom = new THREE.BufferGeometry();
		const playVertices = new Float32Array([
			0.5, 0, 0, -0.5, 0.5, 0, -0.5, -0.5, 0,
		]);
		const playPosition = new THREE.BufferAttribute(playVertices, 3);
		playGeom.setAttribute('position', playPosition);
		const playMesh = new THREE.Mesh(playGeom, material);
		this.playIcon.add(playMesh);
		this.generateUserData(playMesh);
		this.generateUserData(this.playIcon);
	}

	private drawPauseIcon(material: THREE.MeshBasicMaterial): void {
		// Info(Silke): Pause icon (= 2x plane)
		const pauseGeom = new THREE.PlaneGeometry(0.25, 1);
		const leftMesh = new THREE.Mesh(pauseGeom, material);
		const rightMesh = new THREE.Mesh(pauseGeom, material);
		leftMesh.position.x -= 0.25;
		rightMesh.position.x += 0.25;
		this.pauseIcon.add(leftMesh, rightMesh);

		this.generateUserData(leftMesh);
		this.generateUserData(rightMesh);
		this.generateUserData(this.pauseIcon);
	}

	private generateUserData(item: THREE.Mesh | THREE.Group): void {
		item.userData = {
			...this.mesh.userData,
			size: null,
			gridIndex: this.gridIndex,
		};
	}

	private toggleIcon(): void {
		this.playIcon.visible = !this.isPlaying;
		this.pauseIcon.visible = this.isPlaying;
	}

	public toggle(): void {
		this.isPlaying = !this.isPlaying;
		this.toggleIcon();
		this.isPlaying ? this.howl?.play() : this.howl?.pause();
	}

	private addArtist(artist: string): void {
		const geo = new TextGeometry();
		this.group.add(geo);

		// Set properties to configure:
		geo.text = artist.toUpperCase();
		this.setFontSettings(geo);

		// Set Position
		geo.position.x = this.mesh.position.x + this.mesh.scale.x;
		geo.position.y = this.mesh.position.y + this.mesh.scale.y / 4;
		geo.position.z = 1;

		this.generateUserData(geo);
		geo.userData.position = new THREE.Vector3().copy(geo.position);

		if (isIntroEnabled) {
			geo.visible = false;
		}
	}

	private addTitle(artist: string, hasArtist: boolean): void {
		const geo = new TextGeometry();
		this.group.add(geo);

		// Set properties to configure:
		geo.text = artist.toUpperCase();
		this.setFontSettings(geo);

		// Set Position
		const padding = hasArtist ? this.mesh.scale.y / 4 : 0;
		geo.position.x = this.mesh.position.x + this.mesh.scale.x;
		geo.position.y = this.mesh.position.y - padding;
		geo.position.z = 1;

		this.generateUserData(geo);
		geo.userData.position = new THREE.Vector3().copy(geo.position);

		if (isIntroEnabled) {
			geo.visible = false;
		}
	}

	private setFontSettings(geo: TextGeometry): void {
		geo.letterSpacing = this.letterSpacing;
		geo.fontSize = this.fontSize;
		if (typeof window !== 'undefined') {
			geo.font = `${window.location.origin}/fonts/HelveticaNeue-CondensedBold.woff`;
		}

		geo.color = this.color;
		geo.maxWidth = '400';
		geo.anchorY = 'middle';
	}
}

export default Audio;
