import React, { ReactElement, useState } from 'react';

import { Image } from '../Image';
import { IMAGE_DECODING_TYPES, IMAGE_LOADING_TYPES } from '../Image/Image.const';
import { ICarouselProps } from './Carousel.types';

export const Carousel: React.FC<ICarouselProps> = ({
	className,
	images,
	objectFit = 'contain',
	startIndex = 0,
}): ReactElement | null => {
	const [currentImage, setCurrentImage] = useState<number>(startIndex);

	const handleClicked = () => {
		const nextImage = currentImage + 1 >= images.length ? 0 : currentImage + 1;

		setCurrentImage(nextImage);
	};

	if (!images || !images.length || !images[currentImage]) {
		return null;
	}

	return (
		<div className={`c-carousel ${className || ''}`} onClick={handleClicked}>
			{images.map((img, i) => (
				<div
					key={img.id}
					className="c-landing-page__image-container"
					style={{
						visibility: i === currentImage ? 'visible' : 'hidden',
						zIndex: i === currentImage ? 100 : 0,
					}}>
					<Image
						objectFit={objectFit}
						image={img}
						wrapperClassName="c-landing-page__image-wrapper c-landing-page__image-wrapper--visible"
						imageClassName="c-landing-page__image"
						srcsetSizes={[1024, 800, 400]}
						sizes={
							'(min-width: 720px) 50vw, (min-width: 990px) 30vw, 100vw'
						}
						getHQWebp
						loading={IMAGE_LOADING_TYPES.Eager}
						decoding={IMAGE_DECODING_TYPES.Sync}
					/>
				</div>
			))}
		</div>
	);
};
