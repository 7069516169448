import gsap from 'gsap';
import * as THREE from 'three';

import { ANIMATION_HELPERS, ANIMATION_TYPE } from '../../const/animations.const';
import { GRID_CONTENT_BLOCK_TYPES } from '../../const/data.const';
import { handleBlur } from './blur.animations';
import { animateHero } from './hero.animations';
import { handleOpacity } from './opacity.animations';
import { handleScale } from './scale.animations';
import { animateVideoToFullscreen } from './video.animations';

export const transitionToDetail = (
	camera: THREE.PerspectiveCamera,
	activeMesh: THREE.Mesh,
	inactiveMeshes: THREE.Mesh[],
	hasLink: boolean,
	onComplete: () => void = (): void => {},
	onClose: () => void,
): void => {
	animateInactiveOut(inactiveMeshes);
	animateActiveIn(activeMesh, camera, hasLink, onComplete, onClose);
};

const animateInactiveOut = (meshes: THREE.Mesh[]): void => {
	// Grid
	meshes.forEach((mesh: THREE.Mesh) => {
		if (!mesh.visible) {
			return;
		}

		handleScale(mesh, 0.6, ANIMATION_HELPERS.durationXLarge);
		handleOpacity(mesh, ANIMATION_TYPE.OUT, ANIMATION_HELPERS.durationXLarge);
	});
};

const animateActiveIn = (
	mesh: THREE.Mesh,
	camera: THREE.PerspectiveCamera,
	hasLink: boolean,
	onComplete: () => void,
	onClose: () => void,
): void => {
	// Info(Katia): Send to data, that this was the active image
	mesh.userData.isActive = true;

	// Info(Katia): Keep track of original position
	mesh.userData.position = new THREE.Vector3().copy(mesh.position);
	mesh.userData.ignoreHover = true;

	// Remove blur
	handleBlur(mesh, ANIMATION_TYPE.OUT);

	// Zoom image to fill sreen
	if (mesh.userData.type === GRID_CONTENT_BLOCK_TYPES.IMAGE) {
		animateHero(mesh, camera, false, hasLink, onClose);
	} else {
		animateVideoToFullscreen(mesh, camera, onComplete);
	}
};

export const resetTransition = (
	meshes: THREE.Mesh[],
	onComplete: () => void = (): void => {},
) => {
	// Grid
	meshes.forEach((mesh: THREE.Mesh, i: number) => {
		if (!mesh.visible) {
			return;
		}

		if (mesh.userData.isActive === true) {
			// Info(Katia): Reset position

			gsap.to(mesh.position, {
				x: mesh.userData.position.x,
				y: mesh.userData.position.y,
				duration: ANIMATION_HELPERS.durationLarge,
				ease: ANIMATION_HELPERS.ease,
				onComplete: () => {
					handleBlur(mesh, ANIMATION_TYPE.IN);
				},
			});

			mesh.userData.isActive = false;
			mesh.userData.ignoreHover = true;
			setTimeout(() => {
				mesh.userData.ignoreHover = false;
				mesh.userData.position.z = 1;
				mesh.position.z = 1;
			}, 2500);

			/** VIDEO ANIMATIONS */
			if (mesh.userData.type === GRID_CONTENT_BLOCK_TYPES.VIDEO) {
				mesh.userData.video.play();
				handleOpacity(
					mesh,
					ANIMATION_TYPE.IN,
					ANIMATION_HELPERS.durationXLarge,
				);
				gsap.fromTo(
					'.c-video',
					{
						opacity: 1,
					},
					{
						opacity: 0,
						delay: ANIMATION_HELPERS.durationXSmall,
						duration: ANIMATION_HELPERS.duration,
						ease: ANIMATION_HELPERS.ease,
						onComplete: () => {
							const video = document.querySelector('.c-video');
							if (video) {
								const container =
									document.querySelector('.c-container');
								container?.removeChild(video);
							}
						},
					},
				);
			}
		} else {
			handleOpacity(
				mesh,
				ANIMATION_TYPE.IN,
				ANIMATION_HELPERS.durationXLarge,
				// Do callback on last mesh
				i === meshes.length - 1 ? onComplete : () => {},
			);
		}

		handleScale(mesh, 1, ANIMATION_HELPERS.durationXLarge);
	});
};
