/**
 * TILE
 * A tile is a mesh in the grid that can be filled with images, video, text, ...
 */
import * as THREE from 'three';

import { ISize } from '../../types/grid.types';

class Tile {
	public geometry: THREE.PlaneGeometry;
	public mesh: THREE.Mesh;

	private size: ISize;
	private position: { x: number; y: number };

	constructor(
		width: number,
		height: number,
		x: number,
		y: number,
		material: THREE.MeshBasicMaterial,
	) {
		this.geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
		this.size = { width, height };
		this.position = { x, y };

		// Info(Katia): Temporary material before adding shaders
		this.mesh = new THREE.Mesh(this.geometry, material);

		this.updateScale();
		this.updatePosition();
	}

	updateScale(): void {
		this.mesh.scale.set(this.size.width, this.size.height, 1);
		this.mesh.userData.size = this.size;
	}

	updatePosition(): void {
		this.mesh.position.set(this.position.x, this.position.y, 0);
		this.mesh.userData.position = new THREE.Vector3().copy(this.mesh.position);
	}
}

export default Tile;
