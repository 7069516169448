import * as THREE from 'three';

import { ANIMATION_HELPERS, ANIMATION_TYPE } from '../../const/animations.const';
import { DEVICES } from '../../const/devices.const';
import { PERF } from '../../const/perf.const';
import { IGridTexture, ISize } from '../../types/grid.types';
import { detectMobileOrTablet } from '../../utils/detectMobileTablet.util';
import { getPerf } from '../../utils/detectPerf.util';
import getWindowSize from '../../utils/getWindow.util';
import { BLUR_MAX } from '../animations/blur.animations';
import { handleOpacity } from '../animations/opacity.animations';
import fragment from '../shaders/blur/fragment.glsl';
import vertex from '../shaders/blur/vertex.glsl';

class Image {
	public mesh: THREE.Mesh;

	private size: ISize = { width: 0, height: 0 };
	private texture: IGridTexture | null = null;
	private uniforms: { [key: string]: { value: any } };
	private isMobileOrTablet = detectMobileOrTablet();
	private window = getWindowSize();
	private perf = getPerf();

	constructor(mesh: THREE.Mesh) {
		this.mesh = mesh;

		this.size.width = mesh.scale.x;
		this.size.height = mesh.scale.y;

		const uniforms = (this.mesh.material as THREE.ShaderMaterial)?.uniforms;
		if (uniforms) {
			// Info(Katia): Prev uniforms
			this.uniforms = uniforms;
			this.uniforms.uTexture.value = null;
		} else {
			this.uniforms = {
				uTexture: {
					value: null,
				},
				uBlurStrength: {
					value:
						this.isMobileOrTablet !== DEVICES.DESKTOP ||
						this.perf < PERF.PERF_GOOD
							? 0.0
							: BLUR_MAX,
				},
				uResolution: {
					value: [this.window.width, this.window.height, 0],
				},
				uOpacity: {
					value: 0.0,
				},
			};
		}
	}

	setTexture(texture: IGridTexture): void {
		this.texture = texture;
	}

	updateScale(): void {
		this.mesh.scale.set(this.size.width, this.size.height, 1);
	}

	updateUserData(): void {
		this.mesh.userData = {
			...this.mesh.userData,
			size: this.size,
		};
	}

	setSize() {
		if (!this.texture) {
			return;
		}

		const textureIsHorizontal = this.texture.aspectRatio < 1;

		// Info(Katia) Fit texture in mesh shape
		this.size = {
			width: textureIsHorizontal
				? this.mesh.scale.x
				: this.mesh.scale.y / this.texture.aspectRatio,
			height: textureIsHorizontal
				? this.mesh.scale.x * this.texture.aspectRatio
				: this.mesh.scale.y,
		};

		// Large vertical image
		if (this.size.width > this.mesh.scale.x) {
			this.size = {
				width: this.mesh.scale.x,
				height: this.mesh.scale.x * this.texture.aspectRatio,
			};
		}

		// Large horizontal image
		if (this.size.height > this.mesh.scale.y) {
			this.size = {
				width: this.mesh.scale.y / this.texture.aspectRatio,
				height: this.mesh.scale.y,
			};
		}
	}

	updateTexture(): void {
		if (!this.texture) {
			return;
		}

		this.setSize();

		// Info(Katia): Update shader variables
		this.uniforms.uTexture.value = this.texture.texture;

		// Remove previous material in memory
		(this.mesh.material as THREE.Material)?.dispose();

		// Info(Katia): Update material with shader
		this.mesh.material = new THREE.ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader: vertex,
			fragmentShader: fragment,
			transparent: false,
			precision: 'lowp',
		});

		// Animate in
		handleOpacity(
			this.mesh,
			ANIMATION_TYPE.IN,
			ANIMATION_HELPERS.durationSmall,
			() => {
				this.uniforms.uOpacity.value = 1.0;
			},
		);
		this.updateScale();
		// Most userdata is set in grid class
		this.updateUserData();
	}
}

export default Image;
