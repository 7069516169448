import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Player from '@vimeo/player';

import { SCROLL_KEYS } from '../../const/locomotiveScroll.const';
import { IVideoProps } from './Video.types';

export const Video: React.FC<IVideoProps> = ({
	id,
	isPlaying = false,
}: IVideoProps): ReactElement => {
	const videoRef = useRef<HTMLIFrameElement | null>(null);

	const [player, setPlayer] = useState<Player | null>(null);

	useEffect(() => {
		const $el = videoRef?.current;

		if ($el) {
			setPlayer(new Player($el));
		}

		return () => {
			setPlayer(null);
		};
	}, []);

	useEffect(() => {
		if (player) {
			isPlaying ? player.play() : player.pause();
		}

		return () => {
			if (player) {
				player.pause();
			}
		};
	}, [isPlaying, player]);

	return (
		<div className="c-video" data-scroll data-scroll-id={SCROLL_KEYS.VIDEO}>
			<iframe
				title={id}
				ref={videoRef}
				className="c-video__iframe"
				src={`https://player.vimeo.com/video/${id}?autoplay=1&loop=1&muted=1&controls=1`}
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				scrolling="no"
				allowFullScreen></iframe>
			{/* Allow scrolling over clickable iframe */}
			<div className="c-video__overlay"></div>
		</div>
	);
};
