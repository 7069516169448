// Animation Constants
export const ANIMATION_HELPERS = {
	duration: 0.8,
	durationSmall: 0.6,
	durationXSmall: 0.4,
	durationLarge: 1.2,
	durationXLarge: 2,
	durationXXLarge: 2.8,
	ease: 'power4.inOut',
	stagger: '-=0.5',
};

export enum ANIMATION_TYPE {
	IN = 'in',
	OUT = 'out',
}
