import { useStaticQuery, graphql } from 'gatsby';

import { IButtons } from '../../types/data.types';

export const useButtons = (): IButtons => {
	const data = useStaticQuery(graphql`
		query {
			craft {
				globalSet(handle: "buttons") {
					... on Craft_buttons_GlobalSet {
						buttonDownload {
							... on Craft_buttonDownload_button_BlockType {
								label
								accessibilityLabel
							}
						}
						buttonMenu {
							... on Craft_buttonMenu_button_BlockType {
								accessibilityLabel
								label
							}
						}
						buttonNext {
							... on Craft_buttonNext_button_BlockType {
								accessibilityLabel
								label
							}
						}
						buttonPrevious {
							... on Craft_buttonPrevious_button_BlockType {
								accessibilityLabel
								label
							}
						}
						buttonBack {
							... on Craft_buttonBack_button_BlockType {
								accessibilityLabel
								label
							}
						}
						buttonClose {
							... on Craft_buttonClose_button_BlockType {
								accessibilityLabel
								label
							}
						}
					}
				}
			}
		}
	`);

	const buttons = data.craft.globalSet;
	const formattedButtons: IButtons = {};

	Object.keys(buttons).forEach((key: string): void => {
		const d = buttons[key][0];

		if (d) {
			formattedButtons[key] = d;
		}
	});

	return formattedButtons;
};
