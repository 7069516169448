import { IWindow } from '../types/window.types';

const getWindowSize = (): IWindow => {
	if (typeof window !== 'undefined') {
		return {
			width: window.innerWidth,
			height: window.innerHeight,
			aspectRatio: window.innerWidth / window.innerHeight,
		};
	}

	return {
		width: 0,
		height: 0,
		aspectRatio: 0,
	};
};

export default getWindowSize;
