import { IGridItem } from '../../types/grid.types';

const items: IGridItem[] = [
	{
		x: 0,
		y: 0,
		width: 250,
		height: 250,
	},
	{
		x: -175,
		y: -250,
		width: 150,
		height: 200,
	},
	{
		x: 50,
		y: 300,
		width: 100,
		height: 200,
	},
	{
		x: 250,
		y: -100,
		width: 150,
		height: 200,
	},
	{
		x: -150,
		y: 250,
		width: 200,
		height: 100,
	},
	{
		x: -250,
		y: 50,
		width: 150,
		height: 100,
	},
	{
		x: 250,
		y: 250,
		width: 100,
		height: 100,
	},
	{
		x: 100,
		y: -250,
		width: 100,
		height: 100,
	},
];

export { items };
