export const detectIE = (): boolean => {
	if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
		const ua = navigator.userAgent;
		const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

		return isIE;
	}

	return true;
};

export const detectSafari = (): boolean => {
	if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
		const ua = navigator.userAgent;
		const isSafari =
			(ua.indexOf('safari') > -1 || ua.indexOf('Safari') > -1) &&
			ua.indexOf('Chrome') === -1 &&
			ua.indexOf('chrome') === -1;

		return isSafari;
	}

	return false;
};

export const detectIOS = (): boolean => {
	if (typeof navigator === 'undefined') {
		return false;
	}

	const platform =
		navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
};
