import { useCallback, useEffect, useRef, useState } from 'react';

import { use100vh } from 'react-div-100vh';

import { isBrowser } from '../utils/isBrowser.util';

const useResize = (): { isResizing: boolean } => {
	const timerResize = useRef<NodeJS.Timeout | number>();

	const [isResizing, setIsResizing] = useState<boolean>(false);
	const [prevHeight, setPrevHeight] = useState<number | null>(null);

	const height: number | null = use100vh();

	// Info(Katia): Debounce resize
	const handleResize = useCallback(() => {
		if (isResizing) {
			setIsResizing(false);
		}

		clearTimeout(timerResize.current as number);

		// Info (Katia): Changing the timer will affect lock/unlock behaviour on ipad
		timerResize.current = setTimeout(() => {
			setIsResizing(true);
		}, 300);
	}, [isResizing]);

	useEffect(() => {
		if (!isBrowser) {
			return;
		}

		window.addEventListener('resize', handleResize);
		window?.screen?.orientation
			? window.screen.orientation.addEventListener('change', handleResize)
			: window.addEventListener('orientationchange', handleResize);

		return () => {
			if (!isBrowser) {
				return;
			}

			window.removeEventListener('resize', handleResize);
			window?.screen?.orientation
				? window.screen.orientation.removeEventListener(
						'change',
						handleResize,
				  )
				: window.removeEventListener('orientationchange', handleResize);
			clearTimeout(timerResize.current as number);
		};
	}, [handleResize]);

	useEffect(() => {
		if (!height) {
			return;
		}

		if (!prevHeight) {
			setPrevHeight(height);
		}

		if (prevHeight !== height) {
			handleResize();
			setPrevHeight(height);
		}
	}, [handleResize, height, prevHeight]);

	return {
		isResizing,
	};
};

export default useResize;
