import React, { ReactElement, useState } from 'react';

import { AppContext } from './app.context';

export const AppProvider: React.FC = ({ children }): ReactElement => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [isGridInitialised, setIsGridInitialised] = useState<boolean>(false);
	const [isGridVisible, setIsGridVisible] = useState<boolean>(false);
	const [isGridEnabled, setIsGridEnabled] = useState<boolean>(false);
	const [isMenuButtonDark, setIsMenuButtonDark] = useState<boolean>(false);
	const [isAnimating, setIsAnimating] = useState<boolean>(false);
	const [isCampaignVisible, setIsCampaignVisible] = useState<boolean>(true);

	return (
		<AppContext.Provider
			value={{
				isMenuOpen,
				setIsMenuOpen,
				isGridInitialised,
				setIsGridInitialised,
				isGridVisible,
				setIsGridVisible,
				isGridEnabled,
				setIsGridEnabled,
				isMenuButtonDark,
				setIsMenuButtonDark,
				isAnimating,
				setIsAnimating,
				isCampaignVisible,
				setIsCampaignVisible,
			}}>
			{children}
		</AppContext.Provider>
	);
};
