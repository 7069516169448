import React, { ReactElement } from 'react';

import cx from 'classnames';

import { detectSafari } from '../../utils/detectBrowser.util';
import getRelativeUrl from '../../utils/getRelativeUrl.util';
import { IMAGE_DECODING_TYPES, IMAGE_LOADING_TYPES } from './Image.const';
import { IImageProps } from './Image.types';

export const Image: React.FC<IImageProps> = ({
	image,
	wrapperClassName,
	imageClassName,
	sizes,
	srcsetSizes,
	objectFit = 'cover',
	getHQWebp,
	loading = IMAGE_LOADING_TYPES.Lazy,
	decoding = IMAGE_DECODING_TYPES.Async,
}: IImageProps): ReactElement => {
	if (!image) {
		return <div></div>;
	}

	const { title, url, hasFocalPoint, focalPoint, urlHQ, urlHQFallback } = image;

	const imageUrl = getHQWebp
		? detectSafari()
			? urlHQFallback || url
			: urlHQ || url
		: url;

	const src = getRelativeUrl(imageUrl);

	let style = {};

	if (objectFit === 'cover') {
		style = {
			objectFit: 'cover',
			objectPosition:
				hasFocalPoint && focalPoint
					? `${focalPoint[0] * 100}% ${focalPoint[1] * 100}%`
					: 'center center',
		};
	}

	const getSrcSet = (type: 'Webp' | 'Jpg'): string => {
		let srcset = '';
		for (let i = 0; i < srcsetSizes.length; i++) {
			const size = srcsetSizes[i];
			const url: string = image[`url${type}${size}` as keyof typeof image];
			if (url) {
				srcset += `${getRelativeUrl(url)} ${size}w`;

				if (i < srcsetSizes.length - 1) {
					srcset += `,`;
				}
			}
		}

		return srcset;
	};

	return (
		<div className={cx('c-image__wrapper', wrapperClassName)}>
			<picture className={cx('c-image', imageClassName)} style={style}>
				<source
					type="image/webp"
					srcSet={getSrcSet('Webp')}
					sizes={sizes || '100vw'}
				/>
				<img
					decoding={decoding}
					loading={loading}
					style={style}
					src={src}
					srcSet={getSrcSet('Jpg')}
					sizes={sizes || '100vw'}
					alt={title || ''}></img>
			</picture>
		</div>
	);
};
