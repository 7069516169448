import gsap from 'gsap';

import { ANIMATION_HELPERS, ANIMATION_TYPE } from '../../const/animations.const';

export const VISIBLE = 1.0;
export const HIDDEN = 0.0;

export const handleOpacity = (
	mesh: THREE.Mesh,
	type: ANIMATION_TYPE,
	duration: number = ANIMATION_HELPERS.durationSmall,
	onComplete: () => void = (): void => {},
	delay: number = 0,
): void => {
	const uniforms = (mesh.material as THREE.ShaderMaterial).uniforms;

	const updatedOpacity = {
		value: type === ANIMATION_TYPE.IN ? HIDDEN : VISIBLE,
	};

	gsap.to(updatedOpacity, {
		value: type === ANIMATION_TYPE.IN ? VISIBLE : HIDDEN,
		duration,
		delay,
		ease: ANIMATION_HELPERS.ease,
		onUpdate: () => {
			if (uniforms && uniforms.uOpacity) {
				uniforms.uOpacity.value = updatedOpacity.value;
			}
			(mesh.material as THREE.Material).opacity = updatedOpacity.value;
		},
		onComplete,
	});
};
