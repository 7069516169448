import gsap from 'gsap';

import { ANIMATION_HELPERS, ANIMATION_TYPE } from '../../const/animations.const';
import { VIDEO_RATIO } from '../../const/video.const';
import getWindowSize from '../../utils/getWindow.util';
import { getVimeoIframe } from '../utils/getVimeoIframe';
import { visibleHeightAtZDepth, visibleWidthAtZDepth } from '../utils/getZDepth';
import { handleOpacity } from './opacity.animations';

const createVideo = (mesh: THREE.Mesh): void => {
	const video = getVimeoIframe(
		mesh.userData.videoId,
		mesh.userData.video.currentTime,
	);
	const container = document.querySelector('.c-container');
	container?.appendChild(video);
};

const hideClosebutton = () => {
	const close = document.getElementsByClassName('c-button--close')[0];
	close.classList.add('c-button--hide');
	gsap.to('.c-logo', {
		scale: 1,
		duration: ANIMATION_HELPERS.duration,
		ease: ANIMATION_HELPERS.ease,
	});
};

const createCloseButton = (onComplete: () => void) => {
	const close = document.getElementsByClassName('c-button--close')[0];
	close.classList.remove('c-button--hide');
	close.addEventListener('click', () => {
		hideClosebutton();
		onComplete();
	});

	const menu = document.getElementsByClassName('js-button-menu')[0];
	menu.addEventListener('click', () => {
		hideClosebutton();
		onComplete();
	});
};

export const animateVideoToFullscreen = (
	mesh: THREE.Mesh,
	camera: THREE.PerspectiveCamera,
	onComplete: () => void,
) => {
	const windowSizes = getWindowSize();
	const sizes = {
		width: visibleWidthAtZDepth(mesh.position.z, camera),
		height: visibleHeightAtZDepth(mesh.position.z, camera),
	};

	mesh.userData.video.pause();
	createVideo(mesh);
	createCloseButton(onComplete);

	// Calculate max width keeping the aspect ratio in mind
	let fullScreenWidth = sizes.width;
	if (VIDEO_RATIO * sizes.width > sizes.height) {
		fullScreenWidth = sizes.height / VIDEO_RATIO;
	}

	let fullWindowWidth = windowSizes.width;
	if (VIDEO_RATIO * windowSizes.width > windowSizes.height) {
		fullWindowWidth = windowSizes.height / VIDEO_RATIO;
	}

	// Animate
	gsap.to(mesh.position, {
		x: 0 - (mesh?.parent?.position?.x || 0),
		y: 0 - (mesh?.parent?.position?.y || 0),
		z: 2,
		duration: ANIMATION_HELPERS.durationLarge,
		ease: ANIMATION_HELPERS.ease,
	});

	gsap.to(mesh.scale, {
		x: fullScreenWidth,
		y: fullScreenWidth * VIDEO_RATIO,
		z: 1,
		duration: ANIMATION_HELPERS.durationLarge,
		ease: ANIMATION_HELPERS.ease,
	});

	gsap.fromTo(
		'.c-video',
		{
			width: `${fullWindowWidth}px`,
			height: `${fullWindowWidth * VIDEO_RATIO}px`,
			opacity: 0,
		},
		{
			width: `${fullWindowWidth}px`,
			height: `${fullWindowWidth * VIDEO_RATIO}px`,
			opacity: 1,
			delay: ANIMATION_HELPERS.durationLarge,
			duration: ANIMATION_HELPERS.duration,
			ease: ANIMATION_HELPERS.ease,
			onComplete: () => {
				handleOpacity(
					mesh,
					ANIMATION_TYPE.OUT,
					ANIMATION_HELPERS.durationXLarge,
				);
			},
		},
	);
};
