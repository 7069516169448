import * as THREE from 'three';

class Frustum {
	public frustum: THREE.Frustum = new THREE.Frustum();

	constructor(camera: THREE.PerspectiveCamera) {
		this.updateFromCamera(camera);
	}

	updateFromCamera(camera: THREE.PerspectiveCamera) {
		this.frustum.setFromProjectionMatrix(
			new THREE.Matrix4().multiplyMatrices(
				camera.projectionMatrix,
				camera.matrixWorldInverse,
			),
		);
	}

	isInView(object: THREE.Object3D): boolean {
		// Group
		if (object.children.length > 0) {
			const box = new THREE.Box3().setFromObject(object);
			return this.frustum.intersectsBox(box);
		}

		// Mesh
		return this.frustum.intersectsObject(object);
	}
}

export default Frustum;
