import { useStaticQuery, graphql } from 'gatsby';

import { ISeoMetaData } from '../../types/data.types';

export const useSiteMetaData = (): ISeoMetaData => {
	const data = useStaticQuery(graphql`
		query {
			craft {
				seomatic {
					metaJsonLdContainer
					metaLinkContainer
					metaTagContainer
					metaTitleContainer
				}
			}
		}
	`);

	return data.craft.seomatic;
};
