import { IGridItem } from '../../types/grid.types';

const items: IGridItem[] = [
	{
		x: 0,
		y: 0,
		width: 300,
		height: 500,
	},
	{
		x: 400,
		y: -200,
		width: 300,
		height: 200,
	},
	{
		x: -350,
		y: 250,
		width: 150,
		height: 250,
	},
	{
		x: 350,
		y: 350,
		width: 300,
		height: 200,
	},
	{
		x: 800,
		y: 200,
		width: 100,
		height: 200,
	},
	{
		x: -600,
		y: -150,
		width: 250,
		height: 300,
	},
	{
		x: -150,
		y: -500,
		width: 200,
		height: 300,
	},
	{
		x: -850,
		y: -450,
		width: 100,
		height: 150,
	},
	{
		x: 700,
		y: 500,
		width: 150,
		height: 150,
	},
	// EDGES
	{
		x: -250,
		y: 500,
		width: 250,
		height: 150,
	},
	{
		x: 250,
		y: -500,
		width: 350,
		height: 250,
	},
	{
		x: -850,
		y: 200,
		width: 250,
		height: 300,
	},
	{
		x: 800,
		y: -350,
		width: 150,
		height: 300,
	},
];

export { items };
