module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RAF SIMONS","short_name":"RAFSIMONS","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"src/assets/images/favicon.jpg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d657aa22ed51cba3150e36c9e6fe6121"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
