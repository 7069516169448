export const getVimeoIframe = (id: string, time: number): HTMLDivElement => {
	const video = document.createElement('div');
	video.classList.add('c-video');
	video.classList.add('c-video--fullscreen');
	video.innerHTML = `<iframe
			class="c-video__iframe"
			src='https://player.vimeo.com/video/${id}?autoplay=1#t=${time}&muted=1'
			frameBorder="0"
			allow="autoplay; fullscreen; picture-in-picture"
			allowFullScreen></iframe>`;
	return video;
};
