/**
 * LAYOUT
 * layout is the whole set of different grids (grid.class) with tiles (tile.class).
 */
import * as THREE from 'three';

import { IGridItem } from '../../types/grid.types';
import getWindowSize from '../../utils/getWindow.util';
import {
	version1,
	version1mobile,
	version2,
	version2mobile,
	version3,
	version3mobile,
	version4,
	version4mobile,
} from '../grids';
import Camera from './camera.class';
import Content from './content.class';
import Grid from './grid.class';
import Textures from './textures.class';
import Tile from './tile.class';

class Layout {
	public layout = new THREE.Group();
	public gridRecord: Record<string, Grid> = {};

	// Info(Katia): Set of different layout versions
	private versions: THREE.Group[] = [];
	// Info(Katia): Add different loaded versions
	private sizes = getWindowSize();
	private versionsJson =
		this.sizes.width > this.sizes.height
			? [version1, version2, version3, version4]
			: [version1mobile, version2mobile, version3mobile, version4mobile];
	private grids: Grid[] = [];
	private textures: Textures;
	private content: Content;
	private offset: { x: number; y: number } = { x: 0, y: 0 };
	private material: THREE.MeshBasicMaterial;
	private camera: Camera;
	private performanceCallback: (group?: THREE.Group) => void;

	constructor(
		textures: Textures,
		content: Content,
		material: THREE.MeshBasicMaterial,
		camera: Camera,
		performanceCallback: (group?: THREE.Group) => void,
	) {
		this.textures = textures;
		this.content = content;
		this.performanceCallback = performanceCallback;
		this.material = material;
		this.camera = camera;
		this.generateVersions();
		this.generateGrids();
	}

	generateVersions = (): void => {
		for (let json of this.versionsJson) {
			this.generateVersion(json);
		}
	};

	generateVersion = (items: IGridItem[]): void => {
		const version = new THREE.Group();

		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			const tile = new Tile(
				item.width,
				item.height,
				item.x,
				item.y,
				this.material,
			);
			version.add(tile.mesh);
		}

		this.versions.push(version);
	};

	generateGrids(): void {
		// Info(Katia): Create 9 grids
		const positions = [
			new THREE.Vector2(0, 0), // Centered grid

			// row 1
			new THREE.Vector2(-1, 1),
			new THREE.Vector2(0, 1),
			new THREE.Vector2(1, 1),
			// row 2
			new THREE.Vector2(-1, 0),
			new THREE.Vector2(1, 0),
			// row 3
			new THREE.Vector2(-1, -1),
			new THREE.Vector2(0, -1),
			new THREE.Vector2(1, -1),
		];

		for (let i = 0; i < positions.length; i++) {
			const position = positions[i];
			const gridInstance = new Grid(
				this.versions,
				this.textures,
				this.content,
				position,
				i,
				this.camera,
				this.performanceCallback,
			);
			this.grids.push(gridInstance);
			this.gridRecord[i] = gridInstance;
			this.layout.add(gridInstance.grid);
		}
	}

	update(offset: { x: number; y: number }): void {
		if (this.offset.x !== offset.x || this.offset.y !== offset.y) {
			for (let grid of this.grids) {
				grid.move(offset.x * -1, offset.y);
			}
			this.offset = { ...offset };
		}
	}

	resize(clean: () => void, rebuild: () => void) {
		const isPortrait = this.sizes.width > this.sizes.height;

		this.sizes = getWindowSize();
		const isNewSizePortrait = this.sizes.width > this.sizes.height;

		if (isPortrait !== isNewSizePortrait) {
			// Generate new grids
			clean();
			this.grids = [];
			this.layout = new THREE.Group();
			this.versions = [];
			this.versionsJson =
				this.sizes.width > this.sizes.height
					? [version1, version2, version3, version4]
					: [
							version1mobile,
							version2mobile,
							version3mobile,
							version4mobile,
					  ];

			this.generateVersions();
			this.generateGrids();

			rebuild();
		} else {
			for (let grid of this.grids) {
				grid.resize();
			}
		}
	}
}

export default Layout;
