/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Info(Katia): Draws the grid canvas and makes a connection with the vanilla script files
 */
import React, { useEffect } from 'react';

import { navigate } from 'gatsby';

import { CRAFT_SECTIONS } from '../../const/data.const';
import { ROUTES } from '../../const/routes.const';
import { IAppContext } from '../../context/app/app.types';
import { useAppContext } from '../../hooks/context/appContext.hook';
import { useGridData } from '../../hooks/data/grid.hook';
import useResize from '../../hooks/resize.hook';
import * as grid from '../../scripts/grid';
import { IGridContentLink } from '../../types/grid.types';

export const Grid: React.FC = () => {
	const { isResizing } = useResize();
	const { highlights, extended } = useGridData();
	const { isGridInitialised, setIsGridInitialised, setIsAnimating }: IAppContext =
		useAppContext();

	const handleTransitionComplete = (): void => {
		setIsAnimating(false);
	};

	// Info(Katia): Called from the main js when a click should be handled
	const handleClick = (link: IGridContentLink) => {
		// NavigateGrid
		if (link?.external) {
			window.open(link.external, '_blank', 'noopener,noreferrer');
			return;
		}

		if (link?.sectionHandle === CRAFT_SECTIONS.COLLECTIONS) {
			navigate(`/${ROUTES.COLLECTIONS}/${link.internal}`, {
				state: { isGridImage: true },
			});
		}

		if (link?.sectionHandle === CRAFT_SECTIONS.DEFAULT) {
			navigate(`${link.internal}`);
		}
	};

	useEffect(() => {
		if (!isGridInitialised) {
			// Info(Katia): Call init function in the main js to set up the grid
			grid.init(handleClick, handleTransitionComplete);

			grid.loadContent(highlights, extended);
			setIsGridInitialised(true);

			return;
		}

		// Info(Katia): re-enable all listeners and renderers
		grid.reinit();

		return () => {
			grid.setMounted(false);
		};
	}, []);

	useEffect(() => {
		if (isResizing) {
			grid.handleResize();
		}
	}, [isResizing]);

	return <canvas data-scroll-section className="c-canvas js-canvas"></canvas>;
};
