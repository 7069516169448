import React, { PropsWithChildren, useRef, useState, useEffect } from 'react';

import LocomotiveScroll, { Scroll } from 'locomotive-scroll';
import { useDebounce } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';

import { isBrowser } from '../../utils/isBrowser.util';
import { isScrollEnabled } from '../../utils/isScrollEnabled.util';
import { LocomotiveScrollContext } from './locomotiveScroll.context';
import { LocomotiveScrollProviderProps } from './locomotiveScroll.types';

export const LocomotiveScrollProvider: React.FC<LocomotiveScrollProviderProps> = ({
	children,
	options,
	containerRef,
	watch = [],
	onUpdate,
	location,
	onLocationChange,
	isEnabled = true,
}: PropsWithChildren<LocomotiveScrollProviderProps>): any => {
	const scrollRef = useRef<Scroll | null>(null);
	const [isReady, setIsReady] = useState<boolean>(false);

	const { height: containerHeight } = useResizeObserver<HTMLDivElement>({
		ref: containerRef,
	});
	const [height] = useDebounce(containerHeight, 100);

	useEffect(() => {
		if (!isBrowser) {
			return;
		}

		const $scrollContainer = document.querySelector('[data-scroll-container]');
		const scrollEnabled = isScrollEnabled();

		// Disable smooth scroll on bad browsers
		if (!$scrollContainer || !scrollEnabled) {
			return;
		}

		scrollRef.current = new LocomotiveScroll({
			el: $scrollContainer,
			...options,
		});

		setIsReady(true);

		return () => {
			if (isBrowser && scrollRef && scrollRef.current) {
				scrollRef.current.destroy();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!scrollRef || !scrollRef.current || !location) {
			return;
		}

		scrollRef.current.update();

		if (onUpdate) {
			onUpdate(scrollRef.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...watch, height, scrollRef, onUpdate, location]);

	useEffect(() => {
		if (!scrollRef || !scrollRef.current) {
			return;
		}

		scrollRef.current.update();
		scrollRef.current.scrollTo(0, { duration: 0, disableLerp: true });

		if (onLocationChange) {
			onLocationChange(scrollRef.current);
		}
	}, [location, scrollRef, onLocationChange]);

	useEffect(() => {
		if (!scrollRef || !scrollRef.current) {
			return;
		}

		isEnabled ? scrollRef.current.start() : scrollRef.current.stop();

		return () => {
			scrollRef.current.start();
		};
	}, [isEnabled]);

	return (
		<LocomotiveScrollContext.Provider
			value={{ scroll: scrollRef.current, isReady }}>
			{children}
		</LocomotiveScrollContext.Provider>
	);
};
