import React, { ReactElement, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';

import { IPortalProps } from './Portal.types';

export const Portal: React.FC<IPortalProps> = ({
	children,
}: IPortalProps): ReactElement => {
	const [portalRoot, setPortalRoot] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		if (typeof document !== `undefined`) {
			setPortalRoot(document.getElementById('c-portal') as HTMLDivElement);
		}
	}, []);

	if (!portalRoot) {
		return <></>;
	}

	return ReactDOM.createPortal(children, portalRoot);
};
