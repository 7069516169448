import React, { ReactElement } from 'react';

import { FullHeightWrapper } from '../FullHeightWrapper';
import { Image } from '../Image';
import { IHeroProps } from './Hero.types';

export const Hero: React.FC<IHeroProps> = ({
	image,
	title,
	hasImage = true,
}: IHeroProps): ReactElement => (
	<header className="c-hero">
		<h2 className="c-hero__title u-text-c u-color-secondary">{title}</h2>
		<FullHeightWrapper>
			{hasImage && (
				<Image
					image={image}
					srcsetSizes={[1920, 1440, 1024, 800, 400]}
					sizes="100vw"
					wrapperClassName="c-hero__image"
					imageClassName="c-image--cover-top"
					objectFit="cover"
				/>
			)}
		</FullHeightWrapper>
	</header>
);
