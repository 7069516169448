import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { useLocation } from '@reach/router';
import cx from 'classnames';

import { FullHeightWrapper, Grid, MenuButton, Navigation } from '../../components';
import { IAppContext } from '../../context/app/app.types';
import { LocomotiveScrollProvider } from '../../context/locomotiveScroll/locomotiveScroll.provider';
import { useAppContext } from '../../hooks/context/appContext.hook';
import { isScrollEnabled } from '../../utils/isScrollEnabled.util';
import { ILayoutProps } from './Layout.types';

export const Layout: React.FC<ILayoutProps> = ({
	children,
	className,
}): ReactElement => {
	const [isDark, setisDark] = useState<boolean>(false);
	const [hasSmoothScroll, setHasSmoothScroll] = useState<boolean>(true);

	const { pathname } = useLocation();

	const { isGridVisible, isGridEnabled, isCampaignVisible }: IAppContext =
		useAppContext();

	const scrollRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const scrollEnabled = pathname !== '/' && isScrollEnabled(); // index page
		const darkEnabled =
			pathname.includes('about') || // about pages
			pathname.includes('career') || // career pages
			pathname.includes('not-found') || // not found page
			(!pathname.includes('collections') && pathname.length > 1); // default pages

		setHasSmoothScroll(scrollEnabled);
		setisDark(darkEnabled);

		return (): void => {
			setHasSmoothScroll(true);
			setisDark(false);
		};
	}, [pathname]);

	useEffect(() => {
		if (!document) {
			return;
		}

		if (isGridEnabled && !document.body.classList.contains('has-grid-enabled')) {
			document.body.classList.add('has-grid-enabled');
		}

		if (!isGridEnabled && document.body.classList.contains('has-grid-enabled')) {
			document.body.classList.remove('has-grid-enabled');
		}
	}, [isGridEnabled]);

	return (
		<>
			<Navigation />
			<LocomotiveScrollProvider
				isEnabled={hasSmoothScroll}
				containerRef={scrollRef}
				options={{
					smooth: true,
					lerp: 0.05,
					multiplier: 0.5,
					tablet: { smooth: true },
					mobile: { smooth: true },
				}}
				watch={[pathname]}
				location={pathname}>
				<div className="c-container" data-scroll-container ref={scrollRef}>
					<div
						className={cx(
							'u-fill-width',
							!hasSmoothScroll && 'has-default-scroll',
							className,
						)}>
						{isGridVisible && (
							<div
								className={cx(
									'u-pos-absolute u-pos-absolute--fill',
								)}>
								<FullHeightWrapper className="u-overflow-hidden">
									<Grid />
								</FullHeightWrapper>
							</div>
						)}
						<div
							className={cx({
								'c-main__content': true,
								'u-pointer-events-none': isGridEnabled,
							})}>
							{children}
						</div>
					</div>
				</div>
			</LocomotiveScrollProvider>
			<MenuButton
				isDark={isDark}
				className={cx(
					'u-pointer-events-none',
					pathname === '/' && isCampaignVisible && 'u-hide',
				)}
			/>
		</>
	);
};
