import gsap from 'gsap';

import { ANIMATION_HELPERS } from '../../const/animations.const';

const tl = gsap.timeline();

export const animateIn = ($container: HTMLElement): void => {
	const items = $container.getElementsByClassName('c-nav__content');
	const $button = $container.getElementsByClassName('c-nav__item--button');
	const $logo = $container.getElementsByClassName('c-nav__item--logo');
	const $logoSVG = $container.getElementsByClassName('c-logo');

	gsap.set($logoSVG, { scale: 1 });

	tl.fromTo(
		$container,
		{
			yPercent: 100,
			visibility: 'hidden',
		},
		{
			yPercent: 0,
			visibility: 'visible',
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationLarge,
		},
	);

	tl.fromTo(
		items,
		{
			yPercent: 100,
			opacity: 1,
		},
		{
			yPercent: 0,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationLarge,
		},
		'-=0.6',
	);

	tl.fromTo(
		[$logo, $button],
		{ opacity: 0 },
		{
			opacity: 1,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.duration,
		},
		'-=1',
	);
};

export const animateOut = ($container: HTMLElement): void => {
	const items = $container.getElementsByClassName('c-nav__content');
	const $button = $container.getElementsByClassName('c-nav__item--button');
	const $logo = $container.getElementsByClassName('c-nav__item--logo');

	tl.to(items, {
		yPercent: -100,
		ease: ANIMATION_HELPERS.ease,
		duration: ANIMATION_HELPERS.duration,
	});

	tl.to(
		[$logo, $button],
		{
			opacity: 0,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationSmall,
		},
		'-=1',
	);

	tl.to(
		$container,
		{
			yPercent: -100,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.duration,
		},
		'-=0.6',
	);
};

export const animateCollectionsIn = (
	$container: HTMLElement,
	$collectionsContainer: HTMLUListElement,
): void => {
	const items = $collectionsContainer.getElementsByClassName('c-nav__content');
	const $about = $container
		.getElementsByClassName('c-nav__item--about')[0]
		.getElementsByClassName('c-nav__content');
	const $shop = $container
		.getElementsByClassName('c-nav__item--shop')[0]
		.getElementsByClassName('c-nav__content');
	const $collections = $container.getElementsByClassName(
		'c-nav__item--collections-list',
	);

	tl.fromTo(
		[$about, $shop],
		{
			opacity: 1,
			yPercent: 0,
		},
		{
			opacity: 0,
			yPercent: 100,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationSmall,
			onComplete: () => {
				gsap.set($collections, {
					visibility: 'visible',
				});
				gsap.set([$about, $shop], {
					visibility: 'hidden',
				});
			},
		},
	);

	tl.fromTo(
		items,
		{
			opacity: 0,
			yPercent: 100,
		},
		{
			opacity: 1,
			yPercent: 0,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationSmall,
		},
	);
};

export const animateCollectionsOut = (
	$container: HTMLElement,
	$collectionsContainer: HTMLUListElement,
): void => {
	const items = $collectionsContainer.getElementsByClassName('c-nav__content');
	const $about = $container
		.getElementsByClassName('c-nav__item--about')[0]
		.getElementsByClassName('c-nav__content');
	const $shop = $container
		.getElementsByClassName('c-nav__item--shop')[0]
		.getElementsByClassName('c-nav__content');
	const $collections = $container.getElementsByClassName(
		'c-nav__item--collections-list',
	);

	tl.fromTo(
		items,
		{
			opacity: 1,
			yPercent: 0,
		},
		{
			opacity: 0,
			yPercent: 100,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationXSmall,
			onComplete: () => {
				gsap.set($collections, {
					visibility: 'hidden',
				});
				gsap.set([$about, $shop], {
					visibility: 'visible',
				});
			},
		},
	);

	tl.fromTo(
		[$about, $shop],
		{
			opacity: 0,
			yPercent: 100,
		},
		{
			opacity: 1,
			yPercent: 0,
			ease: ANIMATION_HELPERS.ease,
			duration: ANIMATION_HELPERS.durationXSmall,
		},
	);
};
