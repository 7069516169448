import { useStaticQuery, graphql } from 'gatsby';

import { INavigation, INavItems } from '../../types/data.types';

export const useNavigation = (): INavigation => {
	const data = useStaticQuery(graphql`
		query {
			craft {
				entries(section: "collections", level: 1) {
					... on Craft_collections_collection_Entry {
						slug
						title
					}
				}
				globalSet(handle: "navigation") {
					... on Craft_navigation_GlobalSet {
						navigationCollections {
							... on Craft_navigationCollections_navigationItem_BlockType {
								label
								accessibilityLabel
							}
						}
						navigationShop {
							... on Craft_navigationShop_navigationItem_BlockType {
								label
								accessibilityLabel
								shopUrl
							}
						}
						navigationAbout {
							... on Craft_navigationAbout_navigationItem_BlockType {
								label
								accessibilityLabel
							}
						}
					}
				}
			}
		}
	`);

	const navigation = data.craft.globalSet;
	const formatedNavigation: INavItems = {};

	Object.keys(navigation).forEach((key: string): void => {
		const d = navigation[key][0];

		if (d) {
			formatedNavigation[key] = d;
		}
	});

	return {
		collections: data?.craft?.entries || [],
		navigation: formatedNavigation,
	};
};
