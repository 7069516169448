import React, { ReactElement } from 'react';

import cx from 'classnames';

import { IRichTextProps } from './RichText.types';

export const RichText: React.FC<IRichTextProps> = ({
	content,
	className,
}: IRichTextProps): ReactElement => (
	<div
		className={cx('c-richtext', className)}
		dangerouslySetInnerHTML={{ __html: content }}></div>
);
