import { createContext } from 'react';

import { IAppContext } from './app.types';

export const AppContext = createContext<IAppContext>({
	isMenuOpen: false,
	isGridInitialised: false,
	isGridVisible: false,
	isGridEnabled: false,
	isMenuButtonDark: false,
	isAnimating: false,
	isCampaignVisible: true,
	setIsMenuOpen: () => {},
	setIsGridInitialised: () => {},
	setIsGridVisible: () => {},
	setIsGridEnabled: () => {},
	setIsMenuButtonDark: () => {},
	setIsAnimating: () => {},
	setIsCampaignVisible: () => {},
});
