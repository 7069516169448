import React, { ReactElement } from 'react';

import { IAppContext } from '../../context/app/app.types';
import { useAppContext } from '../../hooks/context/appContext.hook';
import { useButtons } from '../../hooks/data/buttons.hook';
import { Button, BUTTON_TYPES } from '../Button';
import { TransitionLink } from '../TransitionLink';
import { IPaginationProps } from './Pagination.types';

export const Pagination: React.FC<IPaginationProps> = ({
	prev,
	next,
	prefix = '',
	hasMenu = false,
}: IPaginationProps): ReactElement => {
	const { buttonNext, buttonPrevious, buttonMenu } = useButtons();
	const { setIsMenuOpen }: IAppContext = useAppContext();

	const handleOpenMenu = (): void => {
		setIsMenuOpen(true);
	};

	return (
		<ul className="c-pagination u-grid u-cols-3 u-mx-10">
			<li className="u-col-start-1 u-col-1">
				{prev && (
					<TransitionLink
						aria-label={buttonPrevious?.accessibilityLabel}
						className="c-pagination__link"
						to={`/${prefix}/${prev.slug}`}>
						{buttonPrevious?.label}
					</TransitionLink>
				)}
			</li>

			{hasMenu && (
				<li className="u-col-start-2 u-col-1 u-text-c">
					<Button
						label={buttonMenu?.label}
						accessibilityLabel={buttonMenu?.accessibilityLabel}
						type={BUTTON_TYPES.MEDIUM}
						onClickButton={handleOpenMenu}
						className="u-color-primary c-button--base-hover"
					/>
				</li>
			)}

			<li className="u-col-start-3 u-col-1 u-text-r">
				{next && (
					<TransitionLink
						aria-label={buttonNext?.accessibilityLabel}
						className="c-pagination__link"
						to={`/${prefix}/${next.slug}`}>
						{buttonNext?.label}
					</TransitionLink>
				)}
			</li>
		</ul>
	);
};
